<template>
  <div v-if="loading">
    <CircularLoadingBar></CircularLoadingBar>
  </div>


  <div v-else class="container-fluid add-project">
    <form>

      <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12">
          <label for="first_name">First Name</label>
          <input v-model="scholarsData.first_name" id="first_name" class="form-control" type="text"
            placeholder="Enter First Name" />
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12">
          <label for="first_name_ar">First Name (In Arabic)</label>
          <input v-model="scholarsData.first_name_ar" id="first_name_ar" class="form-control" type="text"
            placeholder="Enter First Name (in arabic)" />
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-lg-6 col-md-6 col-sm-12">
          <label for="last_name">Last Name</label>
          <input v-model="scholarsData.last_name" id="last_name" class="form-control" type="text"
            placeholder="Enter Last Name" />

        </div>
        <div class="col-lg-6 col-md-6 col-sm-12">
          <label for="last_name_ar">Last Name (In Arabic)</label>
          <input v-model="scholarsData.last_name_ar" id="last_name_ar" class="form-control" type="text"
            placeholder="Enter Last Name (in arabic)" />
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-lg-6 col-md-6 col-sm-12">
          <label for="email">Email Address</label>
          <input v-model="scholarsData.email" id="email" class="form-control" type="email" placeholder="Enter Email" />

        </div>
      </div>



      <div class="mt-4" style="text-align: end">
        <button class="mx-2 btn btn-primary submit" type="submit" @click="checkForm()">
          Submit
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { defineComponent } from "vue"; // Import defineComponent from Vue
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { postData, updateData, getData } from "@/core/services/ApiService";
import CircularLoadingBar from "@/components/widgets/circularloadingbar/CircularLoadingBar.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";

export default defineComponent({
  name: "AuditScholarForm",
  props: {
    id: {
      type: String,
      default: "",
    },
  },
  components: {
    CircularLoadingBar,
  },
  data() {
    return {
      loading: true,
      scholarsData: {
        first_name: "",
        first_name_ar: "",
        last_name: "",
        last_name_ar: "",
        email: "",
      },
    };
  },
  methods: {
    async createData() {
      const endpoint = 'scholars';
      const json_data = JSON.stringify(this.scholarsData);
      const response = await postData(this.$router, endpoint, json_data, "Creating Scholar...");
      if (response.ok) {
        Swal.fire({
          text: "Scholar Created Successfully",
          icon: "success",
          buttonsStyling: false,
          confirmButtonText: "Ok, got it!",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        }).then((result) => {
          if (result.isConfirmed) {
            this.$router.push({ name: "AuditScholars" });
          }
        });
      }
    },
    async getData(id) {
      this.scholarsData = await getData(this.$router, `scholars/${id}`);
    },
    async updateData(id) {
      const endpoint = `scholars`;
      const json_data = JSON.stringify(this.scholarsData);
      const response = await updateData(this.$router, endpoint, json_data, id, "Updating Scholar...");
      if (response.ok) {
        Swal.fire({
          text: "Scholar Updated Successfully",
          icon: "success",
          buttonsStyling: false,
          confirmButtonText: "Ok, got it!",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        }).then((result) => {
          if (result.isConfirmed) {
            this.$router.push({ name: "AuditScholars" });
          }
        });
      }
    },
    async checkForm() {
      let errors = "";

      if (!this.scholarsData.first_name) {
        errors += "First Name is required. \n";
      }
      if (!this.scholarsData.first_name_ar) {
        errors += "First Name (In Arabic) is required. \n";
      }
      if (!this.scholarsData.last_name) {
        errors += "Last Name is required. \n";
      }
      if (!this.scholarsData.last_name_ar) {
        errors += "Last Name (In Arabic) is required. \n";
      }

      if (!this.scholarsData.email) {
        errors += "Email is required. \n";
      }


      if (errors === "") {
        if (this.$props.id) {
          await this.updateData(this.$props.id);
        } else {
          await this.createData();
        }
      } else {
        Swal.fire({
          html: errors, // Use html instead of text to interpret newline characters
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Ok, got it!",
          customClass: {
            confirmButton: "btn btn-primary",
            content: "text-left" // Align text to the left
          },
        });
      }
    },
  },
  async mounted() {
    if (this.$props.id) {
      setCurrentPageBreadcrumbs("Update Scholar");
      await this.getData(this.$props.id);
    } else {
      setCurrentPageBreadcrumbs("Create Scholar");
    }
    this.loading = false;
  },
});
</script>