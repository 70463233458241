<template>
  <div v-if="loading">
    <CircularLoadingBar></CircularLoadingBar>
  </div>

  <div v-else class="container-fluid add-project">
    <form @submit.prevent="checkForm">
      <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12">
          <label for="client_name">Name</label>
          <input
            v-model="client_data.client_name"
            id="client_name"
            class="form-control"
            type="text"
            placeholder="Enter Name"
          />
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12">
          <label for="client_name_ar">Name (In Arabic)</label>
          <input
            v-model="client_data.client_name_ar"
            id="client_name_ar"
            class="form-control"
            type="text"
            placeholder="Enter name (in arabic)"
          />
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-lg-6 col-md-6 col-sm-12">
          <label for="client_shortcode">Short Code</label>
          <input
            v-model="client_data.client_shortcode"
            id="client_shortcode"
            class="form-control"
            type="text"
            placeholder="Enter short code"
          />
        </div>

        <div class="col-lg-6 col-md-6 col-sm-12">
          <label>Shariyah Company</label>
          <SearchableDropdown
            id="shariyah_company"
            placeholder="Select Shariyah Company"
            :options="shariyah_companies"
            v-model="selected_shariyah_company"
          ></SearchableDropdown>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-lg-6 col-md-6 col-sm-12">
          <label for="client_description">Description</label>
          <input
            name="client_description"
            v-model="client_data.client_description"
            id="client_description"
            class="form-control"
            placeholder="Enter description"
          />
        </div>

        <div
          v-if="
            client_data.client_attachment == null ||
            client_data.client_attachment == ''
          "
          class="col-lg-6 col-md-6 col-sm-12"
        >
          <label>Termination letter</label>
          <input
            name="client_attachment"
            id="client_attachment"
            class="form-control"
            type="file"
            accept=".pdf,.doc,.docx"
            @change="handle_file_select"
          />
          <small class="form-text text-muted">
            Please upload a file with a maximum size of 25MB. Accepted formats:
            PDF, DOC, DOCX.
          </small>
        </div>
        <div v-else class="col-lg-6 col-md-6 col-sm-12">
          <label>Termination letter</label>
          <div
            class="d-flex justify-content-between align-items-center fw-bold mt-1"
          >
            <div>
              {{
                `Termination_${this.client_data.client_name}.${this.client_data.client_attachment.split(".")[1]}`
              }}
            </div>
            <div>
              <i
                class="bi bi-trash mx-2 custom-icon"
                style="cursor: pointer"
                title="Delete Attachment"
                @click="delete_termination_letter(client_data.client_id)"
              ></i>
              <i
                class="bi bi-cloud-arrow-down mx-2 custom-icon"
                style="cursor: pointer"
                title="Download Attachment"
                @click="get_termination_letter(client_data.client_id)"
              ></i>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-3">
        <div class="form-check form-switch mx-3 col-lg-6 col-md-6 col-sm-12">
          <label class="form-check-label" for="client_status"
            >Client Status</label
          >
          <input
            class="form-check-input"
            type="checkbox"
            id="client_status"
            v-model="client_data.client_status"
            :true-value="1"
            :false-value="0"
          />
        </div>
      </div>

      <div class="mt-4" style="text-align: end">
        <button class="mx-2 btn btn-primary submit" type="submit">
          Submit
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import {
  postData,
  getData,
  updateData,
  PostFile,
  deleteFile,
  getFile,
} from "@/core/services/ApiService";
import { ShowError, StartLoading, StopLoading } from "@/core/services/AlertService";
import SearchableDropdown from "@/components/SimpleSelect.vue";
import CircularLoadingBar from "@/components/widgets/circularloadingbar/CircularLoadingBar.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";

export default defineComponent({
  name: "ClientListForm",
  props: {
    id: {
      type: String,
      default: "",
    },
  },
  components: {
    SearchableDropdown,
    CircularLoadingBar,
  },

  data() {
    return {
      loading: true,
      client_data: {
        client_name: "",
        client_name_ar: "",
        client_shortcode: "",
        client_description: "",
        client_attachment: null,
        client_status: 1,
        fk_shariyah_company_id: null,
      },
      file: null,
      shariyah_companies: [],
      selected_shariyah_company: null,
    };
  },

  methods: {
    async create_client() {
      StartLoading("Saving Client...");
      const clientData = JSON.stringify(this.createFormData());
      const response = await postData(this.$router, "clients", clientData);
      if (response.ok) {
        // client successfully created
        let client = await response.json();
        if (this.file != null && this.file != "") {
          // uploading file
          let file_url = `clients/termination/${client.client_id}`;
          const formData = new FormData();
          formData.append("file", this.file);
          const response = await PostFile(this.$router, file_url, formData);
          StopLoading();
          if (response.ok) {
            // file successfully posted
            Swal.fire({
              text: "Client saved Successfully",
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            }).then((result) => {
              if (result.isConfirmed) {
                this.$router.push({ name: "ClientList" });
              }
            });
          }
        } else {
          StopLoading();
          // file successfully posted
          Swal.fire({
            text: "Client saved Successfully",
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          }).then((result) => {
            if (result.isConfirmed) {
              this.$router.push({ name: "ClientList" });
            }
          });
        }
      } else {
        StopLoading();
      }
    },

    createFormData() {
      console.log(this.client_data);
      //adding shariyah company foreign key
      const selectedItem = this.shariyah_companies.find(
        (item) => item.value === this.selected_shariyah_company
      );
      this.client_data.fk_shariyah_company_id = selectedItem
        ? selectedItem.key
        : null;
      return this.client_data;
    },

    async updateData() {
      StartLoading("Updating Client...");
      const clientData = JSON.stringify(this.createFormData());
      const response = await updateData(
        this.$router,
        "clients",
        clientData,
        this.$props.id
      );
      if (response.ok) {
        // client successfully updated
        let client = await response.json();
        if (this.file != null && this.file != "") {
          // updating file
          let file_url = `clients/termination/${client.client_id}`;
          const formData = new FormData();
          formData.append("file", this.file);
          const response = await PostFile(this.$router, file_url, formData);
          StopLoading();
          if (response.ok) {
            // file successfully posted
            Swal.fire({
              text: "Client updated Successfully",
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            }).then((result) => {
              if (result.isConfirmed) {
                this.$router.push({ name: "ClientList" });
              }
            });
          }
        } else {
          StopLoading();
          // file successfully posted
          Swal.fire({
            text: "Client updated Successfully",
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          }).then((result) => {
            if (result.isConfirmed) {
              this.$router.push({ name: "ClientList" });
            }
          });
        }
      } else {
        StopLoading();
      }
    },

    handle_file_select(event) {
      const selectedFile = event.target.files[0];
      if (selectedFile) {
        const maxSizeInBytes = 25 * 1024 * 1024;
        if (selectedFile.size > maxSizeInBytes) {
          ShowError(
            "The file is too large. Please select a file smaller than 25MB."
          );
          return;
        }

        const allowedTypes = [
          "application/pdf",
          "application/msword",
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        ];
        if (!allowedTypes.includes(selectedFile.type)) {
          ShowError("Invalid file type. Please select a PDF, DOC, or DOCX file.");
          return;
        }

        this.file = selectedFile;
      }
    },

    async get_client_data(id) {
      const url = `clients/${id}`;
      this.client_data = await getData(this.$router, url);
      //finding shariyah company by id
      const selectedItem = this.shariyah_companies.find(
        (item) => item.key === this.client_data.fk_shariyah_company_id
      );
      this.selected_shariyah_company = selectedItem ? selectedItem.value : null;
    },

    async get_shariyah_companies() {
      const endpoint = "shariyah_companies";
      const data = await getData(this.$router, endpoint);

      this.shariyah_companies = data.map((shariyah_company) => ({
        key: shariyah_company.id,
        value: shariyah_company.name,
      }));
    },

    async checkForm() {
      let errors = "";

      if (!this.client_data.client_name) {
        errors += "Name is required. \n";
      }

      if (!this.client_data.client_shortcode) {
        errors += "Short Code is required. \n";
      }

      if (!this.selected_shariyah_company) {
        errors += "Shariyah company is required. \n";
      }

      if (this.file) {
        if (this.file.size > 25 * 1024 * 1024) {
          // 25MB in bytes
          errors += "Termination letter file size exceeds the 25MB limit. \n";
        }
        const allowedTypes = [
          "application/pdf",
          "application/msword",
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        ];
        if (!allowedTypes.includes(this.file.type)) {
          errors +=
            "Invalid file type. Only PDF, DOC, and DOCX files are allowed. \n";
        }
      }

      if (errors === "") {
        if (this.$props.id) {
          await this.updateData(this.$props.id);
        } else {
          await this.create_client();
        }
      } else {
        Swal.fire({
          text: errors,
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Ok, got it!",
          customClass: { confirmButton: "btn btn-primary" },
        });
      }
    },

    async delete_termination_letter(id) {
      let url = `clients/termination/${id}`;
      let result = await deleteFile(this.$route, url, "Deleting client...");
      if (result.ok) {
        this.client_data.client_attachment = null;
      }
    },
    async get_termination_letter(id) {
      let url = `clients/termination/${id}`;
      const file_name = `Termination_${this.client_data.client_name}.${this.client_data.client_attachment.split(".")[1]}`;
      await getFile(this.$route, url, file_name, "Downloading File...");
    },
  },

  async mounted() {
    await this.get_shariyah_companies();
    if (this.$props.id) {
      setCurrentPageBreadcrumbs("Update Client");
      await this.get_client_data(this.$props.id);
    } else {
      setCurrentPageBreadcrumbs("Create Client");
    }
    this.loading = false;
  },
});
</script>
