<template>
  <div class="searchable-dropdown">
    <input
      v-model="computedModelValue"
      :list="id"
      class="form-control"
      :placeholder="placeholder"
      aria-label="Search for a fruit"
    />
    <datalist :id="id">
      <option
        v-for="option in options"
        :key="option.key"
        :value="option.value"
      ></option>
    </datalist>
    <div class="dropdown-icon bi bi-caret-down-fill"></div>
    <div class="cancel-icon bi bi-x" @click="clearInput"></div>
  </div>
</template>

<script lang="ts">
import "bootstrap-icons/font/bootstrap-icons.css";
import { defineComponent, computed } from "vue";

export default defineComponent({
  props: {
    id: { default: "default" },
    options: { default: [{ key: "select", value: "No Records" }] },
    placeholder: { default: "Enter a Value" },
    modelValue: { default: "" },
  },

  computed: {
    computedModelValue: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      }
    }
  },

  methods: {
    clearInput(){
      this.$emit('update:modelValue', "");
    }
  },
});
</script>

<style scoped>
.searchable-dropdown {
  position: relative;
}

.dropdown-icon,
.cancel-icon {
  position: absolute;
  top: 55%;
  cursor: pointer;
  color: grey; /* Set the color to match your design */
  transform: translateY(-50%);

}

.dropdown-icon {
  right: 10px;
}

.cancel-icon {
  right: 30px;
}
</style>
