// api.ts
import {
  StartLoading,
  StopLoading,
  ShowError,
  ShowLogOutError,
} from "@/core/services/AlertService";
import store from "@/store";
import { Router } from "vue-router";
// Define a base URL for your API
// const process.env.VUE_APP_API_URL as string = "https://srbtest.ameeqcode.com/api"; // Replace with your API's base URL
// const process.env.VUE_APP_API_URL as string = "http://127.0.0.1:8888/api";

// Function to handle GET requests
export async function getData(router, endpoint, loadingtext = "") {
  const isAuthenticated = await store.dispatch("checkAuth");
  if (!isAuthenticated) {
    ShowLogOutError(router);
    return;
  }

  const url = `${process.env.VUE_APP_API_URL as string}/${endpoint}`;

  try {
    if (loadingtext != "") {
      StartLoading(loadingtext);
    }
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: get_token(),
      },
    });
    if (loadingtext != "") {
      StopLoading();
    }
    if (!response.ok) {
      if (response.status == 401) {
        ShowLogOutError(router);
      } else {
        throw_error(response.status);
      }
    }

    return response.json();
  } catch (error) {
    if (error instanceof Error) {
      ShowError(`Error getting data: ${error.message}`);
      throw new Error(`Error getting data: ${error.message}`);
    } else {
      ShowError(`An unknown error occurred.`);
      throw new Error(`An unknown error occurred.`);
    }
  }
}

// Function to handle POST requests
export async function postData(
  router,
  endpoint: string,
  data: any,
  loadingtext = ""
) {
  const isAuthenticated = await store.dispatch("checkAuth");
  if (!isAuthenticated) {
    ShowLogOutError(router);
    return;
  }

  const url = `${process.env.VUE_APP_API_URL as string}/${endpoint}`;

  try {
    if (loadingtext != "") {
      StartLoading(loadingtext);
    }
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: get_token(),
      },
      body: data,
    });
    if (loadingtext != "") {
      StopLoading();
    }
    if (!response.ok) {
      if (response.status == 401) {
        ShowLogOutError(router);
      } else {
        throw_error(response.status);
      }
    }

    return response;
  } catch (error) {
    if (error instanceof Error) {
      ShowError(`Error posting data: ${error.message}`);
      throw new Error(`Error posting data: ${error.message}`);
    } else {
      ShowError(`An unknown error occurred.`);
      throw new Error(`An unknown error occurred.`);
    }
  }
}

// Function to handle UPDATE requests
export async function updateData(
  router,
  endpoint: string,
  data: any,
  id: string,
  loadingtext = ""
) {
  const isAuthenticated = await store.dispatch("checkAuth");
  if (!isAuthenticated) {
    ShowLogOutError(router);
    return;
  }

  const url = `${process.env.VUE_APP_API_URL as string}/${endpoint}/${id}`;

  try {
    if (loadingtext != "") {
      StartLoading(loadingtext);
    }
    const response = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: get_token(),
      },
      body: data,
    });
    if (loadingtext != "") {
      StopLoading();
    }
    if (!response.ok) {
      if (response.status == 401) {
        ShowLogOutError(router);
      } else {
        throw_error(response.status);
      }
    }
    return response;
  } catch (error) {
    if (error instanceof Error) {
      ShowError(`Error Updating: ${error.message}`);
      throw new Error(`Error Updating data: ${error.message}`);
    } else {
      ShowError(`An unknown error occurred.`);
      throw new Error(`An unknown error occurred.`);
    }
  }
}

// Function to handle DELETE requests
export async function deleteData(
  router,
  endpoint: string,
  id: string,
  loadingtext = ""
) {
  const isAuthenticated = await store.dispatch("checkAuth");
  if (!isAuthenticated) {
    ShowLogOutError(router);
    return;
  }

  const url = `${process.env.VUE_APP_API_URL as string}/${endpoint}/${id}`;

  try {
    if (loadingtext != "") {
      StartLoading(loadingtext);
    }
    const response = await fetch(url, {
      method: "DELETE",
      headers: {
        Authorization: get_token(),
      },
    });

    if (loadingtext != "") {
      StopLoading();
    }
    if (!response.ok) {
      if (response.status == 401) {
        ShowLogOutError(router);
      } else {
        throw_error(response.status);
      }
    }

    return response;
  } catch (error) {
    if (error instanceof Error) {
      ShowError(`Error deleting data: ${error.message}`);
      throw new Error(`Error deleting data: ${error.message}`);
    } else {
      ShowError(`An unknown error occurred.`);
      throw new Error(`An unknown error occurred.`);
    }
  }
}

// Function to generate documents
export async function generate_document(router, id: string, type: string) {
  const isAuthenticated = await store.dispatch("checkAuth");
  if (!isAuthenticated) {
    throw_error(401);
    store.dispatch("logout");
    router.push({ name: "sign-in" });
    return;
  }

  try {
    let en_endpoint: string = "";
    let ar_endpoint: string = "";
    let language: string = "";
    let json_data: any = "";
    let filename: string = "";

    StartLoading(`Generating ${type} Report...`);

    let result = {};

    if (type === "Audit") {
      result = await getData(router, `audit_reports/${id}`);
      language = result["document_language"];
      json_data = result["form_data"];
      filename = result["name"];
      en_endpoint = "audit_reports/gen/audit_en_Report";
      ar_endpoint = "audit_reports/gen/audit_ar_Report";
    } else if (type === "SSB") {
      result = await getData(router, `audit_reports/${id}`);
      language = result["document_language"];
      let defaultComplianceText = {
        English:
          "Based on our (i) oversight and supervision of the Company’s affairs related to Shari’a compliance, and (ii) a period-specific review of the activities performed, we report that in our opinion, the Company’s financial arrangements, contracts and transactions with its customers, other financial companies and stakeholders for the year ended 17th April 2025  are in compliance with Shari’a standards and guidelines approved by us.",
        العربية:
          "هذا التقرير وما يتضمنه من رأي مستند على إشرافنا على أعمال الشركة المتعلقة بالالتزام بالضوابط والمعايير الشرعية أولًا، ومراجعة عملياتها وأنشطتها للفترة المحددة ثانيًا، وعليه فإننا نؤكد على أن الترتيبات المالية الخاصة بالشركة والعقود والمعاملات المنفذة مع عملائها والأطراف الأخرى التي أُجريت في السنة المالية المنتهية في 31 ديسمبر 2020 قد تمت وفق الضوابط والمعايير الشرعية المعتمدة.",
        Arabic:
          "هذا التقرير وما يتضمنه من رأي مستند على إشرافنا على أعمال الشركة المتعلقة بالالتزام بالضوابط والمعايير الشرعية أولًا، ومراجعة عملياتها وأنشطتها للفترة المحددة ثانيًا، وعليه فإننا نؤكد على أن الترتيبات المالية الخاصة بالشركة والعقود والمعاملات المنفذة مع عملائها والأطراف الأخرى التي أُجريت في السنة المالية المنتهية في 31 ديسمبر 2020 قد تمت وفق الضوابط والمعايير الشرعية المعتمدة.",
      };
      const form_data = JSON.parse(result["form_data"]);
      json_data = JSON.stringify({
        client_name: form_data["client_name"],
        start_date: form_data["start_date"],
        end_date: form_data["end_date"],
        client_board_name: form_data["client_board_name"],
        scholars: form_data["scholars"],
        // Check if ssb_compliance_data exists and handling default values
        compliance_text:
          form_data["ssb_compliance_data"] &&
          form_data["ssb_compliance_data"]["compliance_text"]
            ? form_data["ssb_compliance_data"]["compliance_text"]
            : defaultComplianceText[language],
        ...(form_data["ssb_compliance_data"] &&
          form_data["ssb_compliance_data"]["additional_opinions"] && {
            additional_opinions:
              form_data["ssb_compliance_data"]["additional_opinions"],
          }),
        ...(form_data["ssb_compliance_data"] &&
          form_data["ssb_compliance_data"]["non_compliance_points"] && {
            non_compliance_points:
              form_data["ssb_compliance_data"]["non_compliance_points"],
          }),
      });

      filename =
        (result["name"] as String).split("_Audit")[0] +
        "_SSB" +
        (result["name"] as String).split("_Audit")[1];
      en_endpoint = "audit_reports/gen/ssb_en_Report";
      ar_endpoint = "audit_reports/gen/ssb_ar_Report";
    } else if (type === "Qualified") {
      result = await getData(router, `qualified_reports/${id}`);
      language = result["document_language"];
      json_data = result["form_data"];
      filename = result["name"];
      en_endpoint = "qualified_reports/gen/qualified_en_Report";
      ar_endpoint = "qualified_reports/gen/qualified_ar_Report";
    } else {
      return;
    }
    let response: Response | undefined;
    if (language === "English") {
      response = await postData(router, en_endpoint, json_data);
    } else {
      response = await postData(router, ar_endpoint, json_data);
    }

    if (response === undefined) {
      throw_error(0);
      StopLoading();
      return;
    }

    if (!response.ok) {
      throw_error(response.status);
      StopLoading();
      return;
    }

    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;

    link.setAttribute("download", filename); // Set the file name here
    document.body.appendChild(link);
    link.click();

    window.URL.revokeObjectURL(url);
    document.body.removeChild(link);
    StopLoading();
  } catch (error) {
    ShowError(`Error downloading file: ${error}`);
    console.error("Error downloading file:", error);
  }
}

// Function to throw status code errors
function throw_error(status: number) {
  let errorMessage = "Unknown error occurred";

  switch (status) {
    case 400:
      errorMessage =
        "Bad request: The server could not understand the request.";
      break;
    case 401:
      errorMessage = "Unauthorized: Session Expired. Login Again!";
      break;
    case 403:
      errorMessage =
        "Forbidden: The server understood the request, but refuses to authorize it.";
      break;
    case 404:
      errorMessage = "Not Found: The requested resource could not be found.";
      break;
    default:
      errorMessage =
        "Network error: An unexpected error occurred on the server.";
      break;
  }

  ShowError(errorMessage);
  throw new Error(errorMessage);
}

function get_token() {
  const authDataString = localStorage.getItem("auth");
  const authData = authDataString ? JSON.parse(authDataString) : null;
  if (authData && authData.idToken) {
    return authData.idToken;
  }
}

export async function login_backend(email: string, token: string) {
  const url = `${process.env.VUE_APP_API_URL as string}/users/login`;

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: get_token(),
      },
      body: JSON.stringify({
        email: email,
        token: token,
      }),
    });

    // if (!response.ok) {
    //   throw_error(response.status);
    // }
    return response;
  } catch (error) {
    if (error instanceof Error) {
      ShowError(`Error Logging In: ${error.message}`);
      throw new Error(`Error Logging In: ${error.message}`);
    } else {
      ShowError(`An unknown error occurred.`);
      throw new Error(`An unknown error occurred.`);
    }
  }
}

// Function to handle DELETE file
export async function deleteFile(router, endpoint: string, loadingtext = "") {
  const isAuthenticated = await store.dispatch("checkAuth");
  if (!isAuthenticated) {
    ShowLogOutError(router);
    return;
  }

  const url = `${process.env.VUE_APP_API_URL as string}/${endpoint}`;

  try {
    if (loadingtext != "") {
      StartLoading(loadingtext);
    }
    const response = await fetch(url, {
      method: "DELETE",
      headers: {
        Authorization: get_token(),
      },
    });

    if (loadingtext != "") {
      StopLoading();
    }
    if (!response.ok) {
      if (response.status == 401) {
        ShowLogOutError(router);
      } else {
        throw_error(response.status);
      }
    }

    return response;
  } catch (error) {
    if (error instanceof Error) {
      ShowError(`Error deleting data: ${error.message}`);
      throw new Error(`Error deleting data: ${error.message}`);
    } else {
      ShowError(`An unknown error occurred.`);
      throw new Error(`An unknown error occurred.`);
    }
  }
}

// Function to handle POST file
export async function PostFile(
  router: Router,
  endpoint: string,
  data: FormData,
  loadingtext = ""
) {
  const isAuthenticated = await store.dispatch("checkAuth");
  if (!isAuthenticated) {
    ShowLogOutError(router);
    return;
  }

  const url = `${process.env.VUE_APP_API_URL as string}/${endpoint}`;

  try {
    if (loadingtext !== "") {
      StartLoading(loadingtext);
    }

    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: get_token(),
      },
      body: data,
    });

    if (loadingtext != "") {
      StopLoading();
    }
    if (!response.ok) {
      if (response.status == 401) {
        ShowLogOutError(router);
      } else {
        throw_error(response.status);
      }
    }

    return response;
  } catch (error) {
    if (error instanceof Error) {
      ShowError(`Error posting data: ${error.message}`);
      throw new Error(`Error posting data: ${error.message}`);
    } else {
      ShowError(`An unknown error occurred.`);
      throw new Error(`An unknown error occurred.`);
    }
  }
}

// Function to handle Put file
export async function getFile(
  router,
  endpoint,
  filename = "",
  loadingtext = ""
) {
  const isAuthenticated = await store.dispatch("checkAuth");
  if (!isAuthenticated) {
    ShowLogOutError(router);
    return;
  }

  try {
    if (loadingtext !== "") {
      StartLoading(loadingtext);
    }

    const response = await fetch(`${process.env.VUE_APP_API_URL as string}/${endpoint}`, {
      method: "GET",
      headers: {
        Authorization: get_token(),
      },
    });

    if (loadingtext !== "") {
      StopLoading();
    }

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename); // Set the final filename with extension
    document.body.appendChild(link);
    link.click();

    window.URL.revokeObjectURL(url);
    document.body.removeChild(link);
    StopLoading();
  } catch (error) {
    if (error instanceof Error) {
      ShowError(`Error posting data: ${error.message}`);
      throw new Error(`Error posting data: ${error.message}`);
    } else {
      ShowError(`An unknown error occurred.`);
      throw new Error(`An unknown error occurred.`);
    }
  }
}
