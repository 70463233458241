<template>
  <div v-if="loading">
    <CircularLoadingBar></CircularLoadingBar>
  </div>
  <div v-else>
    <div class="card">
      <!--begin::Body-->
      <div class="card-body py-0">
        <div class="card-header border-0" style="background-color: transparent">
          <!--begin::Card title-->
          <div class="card-title">
            <!--begin::Search-->
            <div class="d-flex align-items-center position-relative my-1">
              <input
                type="text"
                v-model="search_term"
                @input="search_reports()"
                class="form-control form-control-solid w-250px ps-15"
                placeholder="Search Guidelines"
                style="border: 1px solid grey"
              />
            </div>
            <!--end::Search-->
          </div>
          <!--begin::Card toolbar-->
          <div class="card-toolbar">
            <!--begin::Toolbar-->
            <div class="d-flex justify-content-end">
              <!--begin::Add Guidelines -->
              <button
                type="button"
                class="btn btn-primary"
                @click="new_guidelines_page()"
              >
                New Guideline
              </button>
              <!--end::Add Guidelines -->
            </div>
            <!--end::Toolbar-->
          </div>
          <!--end::Card toolbar-->
        </div>
        <div class="tab-content">
          <!--begin::Tap pane-->
          <div class="tab-pane fade active show">
            <!--begin::Table container-->
            <div>
              <!--begin::Table-->
              <table
                class="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4"
              >
                <!--begin::Table head-->
                <thead>
                  <tr class="border-0"></tr>
                </thead>
                <!--end::Table head-->
                <!--begin::Table body-->
                <tbody>
                  <tr>
                    <td>
                      <div class="align-top symbol symbol-45px me-2">
                        <span class="symbol-label"> # </span>
                      </div>
                    </td>

                    <td
                      v-for="label in column_labels" :key="label.id"
                      class="align-top text-dark fw-bold text-hover-primary mb-1 fs-6"
                    >
                      {{ label }}
                    </td>
                  </tr>

                  <tr
                    v-for="(guidelines, index) in filtered_guidelines_data"
                    :key="index"
                  >
                    <td>
                      <div class="symbol symbol-45px me-2">
                        <span class="symbol-label">
                          {{ index + 1 }}
                        </span>
                      </div>
                    </td>

                    <td class="text-start">
                      <span>
                        {{
                          guidelines["name"]
                            ? guidelines["name"].length > 0
                              ? guidelines["name"].length > 50
                                ? guidelines["name"].substring(0, 50) + "..."
                                : guidelines["name"]
                              : "empty"
                            : "empty"
                        }}
                      </span>
                    </td>

                    <td class="text-start">
                      <span>
                        {{
                          guidelines["heading"]
                            ? guidelines["heading"].length > 0
                              ? guidelines["heading"].length > 50
                                ? guidelines["heading"].substring(0, 50) + "..."
                                : guidelines["heading"]
                              : "empty"
                            : "empty"
                        }}
                      </span>
                    </td>

                


                    <td class="text-start">
                      <div class="d-flex align-items-center">
                        <i
                          class="bi bi-pencil-square mx-2 custom-icon"
                          style="cursor: pointer"
                          title="Edit Guidelines"
                          @click="guidelines_edit_page(guidelines['id'])"
                        ></i>
                        <i
                          class="bi bi-trash mx-2 custom-icon"
                          style="cursor: pointer"
                          title="Delete Guidelines"
                          @click="delete_guidelines(guidelines['id'])"
                        ></i>
                      </div>
                    </td>
                  </tr>
                </tbody>
                <!--end::Table body-->
              </table>
            </div>
            <!--end::Table-->
          </div>
          <!--end::Tap pane-->
        </div>
      </div>
      <!--end::Body-->
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { getData, deleteData } from "@/core/services/ApiService";
import CircularLoadingBar from "@/components/widgets/circularloadingbar/CircularLoadingBar.vue";

export default defineComponent({
  name: "Guidelines",
  components: {
    CircularLoadingBar,
  },
  data() {
    return {
      loading: true,
      GuidelinesListData: [],
      search_term: "",
      column_labels: [
        "Name",
        "Heading",
        "Action",
      ],
      filtered_guidelines_data: [],
    };
  },
  setup() {
    setCurrentPageBreadcrumbs("Guidelines");
  },

  methods: {
    async getData() {
      this.GuidelinesListData = await getData(this.$router, "guidelines");
      this.filtered_guidelines_data = this.GuidelinesListData;
    },

    async delete_guidelines(id) {
      const idToDelete = id;
      await deleteData(this.$router, "guidelines", idToDelete, "Deleting Guidelines...");

      for (let i = 0; i < this.GuidelinesListData.length; i++) {
        if (this.GuidelinesListData[i]["id"] === idToDelete) {
          this.GuidelinesListData.splice(i, 1);
        }
      }
      this.filtered_guidelines_data = this.GuidelinesListData;
    },
    search_reports() {
      const inputValue = this.search_term.toLowerCase();
      this.filtered_guidelines_data = this.GuidelinesListData.filter((option) =>
        String(option["name"]).toLowerCase().includes(inputValue)
      );
    },
    guidelines_edit_page(id) {
      this.$router.push({
        name: "GuidelineForm",
        params: { id: id },
      });
    },
    new_guidelines_page() {
      this.$router.push({ name: "GuidelineForm" });
    },
  },
  async mounted() {
    await this.getData();
    this.loading = false;
  },
});
</script>