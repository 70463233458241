import Swal from "sweetalert2/dist/sweetalert2.js";
import { useRouter } from "vue-router";
import store from "@/store";

export function StartLoading(text: string) {
  if (text != "") {
    Swal.fire({
      title: text,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
  }
}
export function StopLoading() {
  Swal.close();
}

export function ShowError(text: string) {
  if (text != "") {
    Swal.fire({
      text: text,
      icon: "error",
      buttonsStyling: false,
      confirmButtonText: "Ok, got it!",
      customClass: {
        confirmButton: "btn btn-primary",
      },
    });
  }
}

export function ShowLogOutError(router) {
  store.dispatch("logout");
  router.push({ name: "sign-in" });
  Swal.fire({
    title: "Unauthorized!",
    text: "Session Expired. Please login again!",
    icon: "info",
    buttonsStyling: false,
    confirmButtonText: "Ok, got it!",
    customClass: {
      confirmButton: "btn btn-primary",
    },
  });
}
