<template>
  <div v-if="loading">
    <CircularLoadingBar></CircularLoadingBar>
  </div>

  <div v-else class="container-fluid add-project">
    <form>
      <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12">
          <label for="name">Name</label>
          <input
            v-model="user_data['name']"
            id="name"
            class="form-control"
            type="text"
            placeholder="Enter Name"
          />
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12">
          <label for="UserType">Role</label>
          <input
            v-model="user_data['role']"
            id="role"
            class="form-control"
            type="text"
            placeholder="User Role"
          />
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-lg-6 col-md-6 col-sm-12">
          <label for="email">Email</label>
          <input
            v-model="user_data['email']"
            id="email"
            class="form-control"
            type="text"
            placeholder="User Email"
          />
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12">
          <label for="description">Description</label>
          <textarea
            v-model="user_data['description']"
            id="description"
            class="form-control"
            type="text"
            placeholder="User Description"
          ></textarea>
        </div>
        <!-- <div class="col-lg-6 col-md-6 col-sm-12">
          <label for="sectors">Sector</label>
          <input
            v-model="user_data['sector']"
            id="sectors"
            class="form-control"
            type="text"
            placeholder="Select Sectors"
          />
        </div> -->
      </div>

      <!-- <div class="row mt-3">
        
        
        <div class="col-lg-6 col-md-6 col-sm-12">
          <div class="form-check form-switch mt-2">
            <label for="status">{{user_data['status']}}</label>
          <input
            v-model="user_data['status']"
            id="status"
            class="form-check-input"
            type="checkbox"
          ></input>
          </div>
          
        </div>
      </div> -->

      <div class="mt-4" style="text-align: end">
        <button
          class="mx-2 btn btn-primary submit"
          type="submit"
          @click="this.checkForm()"
        >
          Submit
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { postData, updateData, getData } from "@/core/services/ApiService";
import CircularLoadingBar from "@/components/widgets/circularloadingbar/CircularLoadingBar.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";

export default defineComponent({
  name: "user_form",
  props: {
    id: {
      type: String,
      default: "",
    },
  },
  components: {
    CircularLoadingBar,
  },
  data() {
    return {
      loading: true,
      user_data:{},
    };
  },
  methods: {
    async createData() {
      this.user_data.created_at = new Date(Date.now() + 3 * 60 * 60 * 1000)
        .toISOString()
        .split("Z")[0];
      this.user_data.updated_at = this.user_data.created_at;
      const endpoint = "users";
      const json_data = JSON.stringify(this.user_data);
      const response = await postData(this.$router, 
        endpoint,
        json_data,
        "Creating User..."
      );
      if (response.ok) {
        Swal.fire({
          text: "User Created Successfully",
          icon: "success",
          buttonsStyling: false,
          confirmButtonText: "Ok, got it!",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        }).then((result) => {
          if (result.isConfirmed) {
            this.$router.push({ name: "users" });
          }
        });
      }
    },
    async getData(id) {
      const url = `users/${id}`;
      this.user_data = await getData(this.$router, url);
    },
    async updateData(id) {
      const endpoint = `users`;
      this.user_data.updated_at = new Date(Date.now() + 3 * 60 * 60 * 1000)
        .toISOString()
        .split("Z")[0];
      const json_data = JSON.stringify(this.user_data);
      const response = await updateData(this.$router, 
        endpoint,
        json_data,
        id,
        "Updating User..."
      );

      if (response.ok) {
        Swal.fire({
          text: "User Updated Successfully",
          icon: "success",
          buttonsStyling: false,
          confirmButtonText: "Ok, got it!",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        }).then((result) => {
          if (result.isConfirmed) {
            this.$router.push({ name: "users" });
          }
        });
      }
    },
    async checkForm() {
      let errors = "";

      if (!this.user_data['name']) {
        errors += "User Name is required. \n";
      }
      if (!this.user_data['role']) {
        errors += "User Role is required. \n";
      }
      if (!this.user_data['email']) {
        errors += "Email is required. \n";
      }
      if (!this.user_data['description']) {
        errors += "Description is required. \n";
      }
      this.user_data['sector']="all";
      this.user_data['status']="active";
      
      if (errors === "") {
        if (this.$props.id) {
          await this.updateData(this.$props.id);
        } else {
          await this.createData();
        }
      } else {
        Swal.fire({
          text: errors,
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Ok, got it!",
          customClass: {
            confirmButton: "btn btn-primary",
            content: "text-left",
          },
        });
      }
    },
  },
  async mounted() {
    if (this.$props.id) {
      setCurrentPageBreadcrumbs("Update User");
      await this.getData(this.$props.id);
    } else {
      setCurrentPageBreadcrumbs("Create User");
    }
    this.loading = false;
  },
});
</script>
