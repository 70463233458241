<template>
  <div v-if="loading">
    <CircularLoadingBar></CircularLoadingBar>
  </div>


  <div v-else class="container-fluid add-project">
    <form>

      <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12">
          <label for="title">Title</label>
          <input v-model="referencesData.title" id="title" class="form-control" type="text" placeholder="Enter Title" />
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12">
          <label for="titleInArabic">Title (In Arabic)</label>
          <input v-model="referencesData.title_ar" id="titleInArabic" class="form-control" type="text"
            placeholder="Enter Title (in arabic)" />
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-lg-6 col-md-6 col-sm-12">
          <label for="description">Description</label>
          <input v-model="referencesData.description" id="description" class="form-control" type="text"
            placeholder="Enter Description" />

        </div>
        <div class="col-lg-6 col-md-6 col-sm-12">
          <label for="descriptionInArabic">Description (In Arabic)</label>
          <input v-model="referencesData.description_ar" id="descriptionInArabic" class="form-control" type="text"
            placeholder="Enter Description (in arabic)" />
        </div>
      </div>



      <div class="mt-4" style="text-align: end">
        <!-- <button class="mx-2 btn btn-primary submit" type="submit">{{ ButtonText }}</button> -->
        <button class="mx-2 btn btn-primary submit" type="submit" @click="checkForm()">
          Submit
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { postData, updateData, getData } from "@/core/services/ApiService";
import CircularLoadingBar from "@/components/widgets/circularloadingbar/CircularLoadingBar.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";

export default defineComponent({
  name: "AuditReferenceForm",
  props: {
    id: {
      type: String,
      default: "",
    },
  },
  components: {
    CircularLoadingBar,
  },
  data() {
    return {
      loading: true,
      referencesData: {
        title: "",
        title_ar: "",
        description: "",
        description_ar: "",
        created_at: "",
        updated_at: "",
      },
    };
  },
  methods: {
    async createData() {
      this.referencesData.created_at = new Date(Date.now() + 3 * 60 * 60 * 1000)
        .toISOString()
        .split("Z")[0];
      this.referencesData.updated_at = this.referencesData.created_at;
      const endpoint = 'audit_references';
      const json_data = JSON.stringify(this.referencesData);
      const response = await postData(this.$router, endpoint, json_data, "Creating Reference...");
      if (response.ok) {
        Swal.fire({
          text: "Reference Created Successfully",
          icon: "success",
          buttonsStyling: false,
          confirmButtonText: "Ok, got it!",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        }).then((result) => {
          if (result.isConfirmed) {
            this.$router.push({ name: "AuditReferences" });
          }
        });
      }
    },
    async getData(id) {
      const url = `audit_references/${id}`;
      this.referencesData = await getData(this.$router, url);
    },
    async updateData(id) {
      const endpoint = `audit_references`;
      this.referencesData.updated_at = new Date(Date.now() + 3 * 60 * 60 * 1000)
        .toISOString()
        .split("Z")[0];
      const json_data = JSON.stringify(this.referencesData);
      const response = await updateData(this.$router, endpoint, json_data, id, "Updating Reference...");
      
      if (response.ok) {
        Swal.fire({
          text: "Reference Updated Successfully",
          icon: "success",
          buttonsStyling: false,
          confirmButtonText: "Ok, got it!",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        }).then((result) => {
          if (result.isConfirmed) {
            this.$router.push({ name: "AuditReferences" });
          }
        });
      }
     
    },
    async checkForm() {
      let errors = "";

      if (!this.referencesData.title) {
        errors += "Title is required. \n";
      }
      if (!this.referencesData.title_ar) {
        errors += "Title (In Arabic) is required. \n";
      }
      if (!this.referencesData.description) {
        errors += "Description is required. \n";
      }
      if (!this.referencesData.description_ar) {
        errors += "Description (In Arabic) is required. \n";
      }


      if (errors === "") {
        if (this.$props.id) {
          await this.updateData(this.$props.id);
        } else {
          await this.createData();
        }
      } else {
        Swal.fire({
          html: errors,
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Ok, got it!",
          customClass: {
            confirmButton: "btn btn-primary",
            content: "text-left"
          },
        });
      }
    },
  },
  async mounted() {
    if (this.$props.id) {
      setCurrentPageBreadcrumbs("Update References");
      await this.getData(this.$props.id);
    } else {
      setCurrentPageBreadcrumbs("Create References");
    }
    this.loading = false;

  },
});
</script>