import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
// import JwtService from "@/core/services/JwtService";

// Pages
import AuditObservations from '@/views/crafted/pages/Manage Audit Data/AuditObservations.vue';
import AuditObservationForm from '@/views/crafted/pages/Manage Audit Data/AuditObservationForm.vue';
import ShariyahCompanies from '@/views/crafted/pages/Manage Audit Data/ShariyahCompanies.vue';
import ShariyahCompaniesForm from '@/views/crafted/pages/Manage Audit Data/ShariyahCompaniesForm.vue';
import AuditReferences from '@/views/crafted/pages/Manage Audit Data/AuditReferences.vue';
import AuditReferencesForm from '@/views/crafted/pages/Manage Audit Data/AuditReferencesForm.vue';
import AuditDepartments from '@/views/crafted/pages/Manage Audit Data/AuditDepartments.vue';
import AuditDepartmentForm from '@/views/crafted/pages/Manage Audit Data/AuditDepartmentForm.vue';
import AuditScholars from '@/views/crafted/pages/Manage Audit Data/AuditScholars.vue';
import AuditScholarForm from '@/views/crafted/pages/Manage Audit Data/AuditScholarForm.vue' ;
import SectorList from '@/views/crafted/pages/Manage Audit Data/SectorList.vue';
import SectorListForm from '@/views/crafted/pages/Manage Audit Data/SectorListForm.vue' ;
import Guidelines from '@/views/crafted/pages/Manage Audit Data/Guidelines.vue';
import GuidelineForm from '@/views/crafted/pages/Manage Audit Data/GuidelineForm.vue' ;
import Users from '@/views/crafted/pages/Users/Users.vue' ;
import UserForm from '@/views/crafted/pages/Users/UserForm.vue' ;
import ClientsList from '@/views/crafted/pages/Business/ClientsList.vue' ;
import ClientListForm from '@/views/crafted/pages/Business/ClientForm.vue';




const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect:"/dashboard",
    component: () => import("@/layout/Layout.vue"),
    meta: { requiresAuth: true },
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
      },
      {
        path: "/review",
        name: "review",
        component: () => import("@/views/crafted/pages/ProjectReview.vue"),
      },
      {
        path: "/audit-report-en/:audit_report_id?",
        name: "audit-report-en",
        props: true,
        component: () => import("@/views/crafted/pages/AuditReportEnglish.vue"),
      },
      {
        path: "/audit-report-ar/:audit_report_id?",
        name: "audit-report-ar",
        props: true,
        component: () => import("@/views/crafted/pages/AuditReportArabic.vue"),
      },
      {
        path: "/audit-report-history",
        name: "auditreporthistory",
        component: () => import("@/views/crafted/pages/AuditReportHistory.vue"),
      },
      {
        path: "/qualified-report-history",
        name: "qualifiedreporthistory",
        component: () => import("@/views/crafted/pages/QualifiedReportHistory.vue"),
      },
      {
        path: "/qualified-report-en/:qualified_report_id?",
        name: "qualified-report-en",
        props: true,
        component: () => import("@/views/crafted/pages/QualifiedReportEnglish.vue"),
      },
      {
        path: "/qualified-report-ar/:qualified_report_id?",
        name: "qualified-report-ar",
        props: true,
        component: () => import("@/views/crafted/pages/QualifiedReportArabic.vue"),
      },
      {
        path: "/ssb-report-en/:ssb_report_id?",
        name: "ssb-report-en",
        props: true,
        component: () => import("@/views/crafted/pages/SSBReportEnglish.vue"),
      },
      {
        path: "/ssb-report-ar/:ssb_report_id?",
        name: "ssb-report-ar",
        props: true,
        component: () => import("@/views/crafted/pages/SSBReportArabic.vue"),
      },
      {
        path: "/audit-observations",
        name: "AuditObservations",
        component: AuditObservations
      },
      {
        path: "/audit-observation/form/:id?",
        name: "AuditObservationForm",
        component: AuditObservationForm,
        props: true,
      },
      {
        path: "/audit-references",
        name: "AuditReferences",
        component: AuditReferences
      },
      {
        path: "/audit-references/form/:id?",
        name: "AuditReferencesForm",
        component: AuditReferencesForm,
        props: true,
      },
      {
        path: "/shariyah-companies",
        name: "ShariyahCompanies",
        component: ShariyahCompanies
      },
      {
        path: "/shariyah-companies/form/:id?",
        name: "ShariyahCompaniesForm",
        component: ShariyahCompaniesForm,
        props: true,
      },
      {
        path: "/audit-departments",
        name: "AuditDepartments",
        component: AuditDepartments
      },
      {
        path: "/audit-departments/form/:id?",
        name: "AuditDepartmentForm",
        component: AuditDepartmentForm,
        props: true,
      },
      {
        path: "/audit-scholars",
        name: "AuditScholars",
        component: AuditScholars
      },
      {
        path: "/audit-scholars/form/:id?",
        name: "AuditScholarForm",
        component: AuditScholarForm,
        props: true,
      },

      {
        path: "/sector-list",
        name: "SectorList",
        component: SectorList
      },

      {
        path: "/sector-list/form/:id?",
        name: "SectorListForm",
        component: SectorListForm,
        props: true,
      },
      {
        path: "/guidelines",
        name: "Guidelines",
        component: Guidelines
      },

      {
        path: "/guidelines/form/:id?",
        name: "GuidelineForm",
        component: GuidelineForm,
        props: true,
      }, 
      {
        path: "/clients",
        name: "ClientList",
        component: ClientsList
      },

      {
        path: "/client-list/form/:id?",
        name: "ClientListForm",
        component: ClientListForm,
        props: true,
      }, 


      {
        path: "/users",
        name: "users",
        component: Users,
        
      },
      {
        path: "/users/new",
        name: "add_user",
        component: UserForm
      }, 
      {
        path: "/users/edit/:id?",
        name: "edit_user",
        component: UserForm,
        props: true,
      }
      

    ],
  },
  {
    path: "/",
    component: () => import("@/components/page-layouts/Auth.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
      },
    ],
  },
  {
    path: "/collect-response-en/:audit_report_id/:audit_report_name",
    name: "collect-response-en",
    props: true,
    component: () =>
      import("@/views/crafted/pages/ResponseFormEnglish.vue"),
  },
  {
    path: "/collect-response-ar/:audit_report_id/:audit_report_name",
    name: "collect-response-ar",
    props: true,
    component: () =>
      import("@/views/crafted/pages/ResponseFormArabic.vue"),
  },
  {
    path: "/404",
    name: "404",
    component: () => import("@/views/crafted/authentication/Error404.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    try {
      const isAuthenticated = await store.dispatch('checkAuth');
      if (isAuthenticated) {
        next();
      } else {
        store.dispatch('logout');
        next({ name: 'sign-in' }); // Redirect to login if not authenticated
      }
    } catch (error) {
      store.dispatch('logout');
      next({ name: 'sign-in' }); // Redirect to login on error
    }
  } else {
    next(); // Allow navigation if not requiring authentication
  }
});
export default router;
