
<template>
    <div class="circular-loading-bar">
      <div class="circle"></div>
    </div>
  </template>
  
  <script>
  export default {
    name: "CircularLoadingBar",
  };
  </script>
  
  <style scoped>
  .circular-loading-bar {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .circle {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 4px solid #ccc;
    border-top: 4px solid #3498db;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  </style>
  