<template>
    <div class="truncated-text" :title="text">
      {{ truncated }}
    </div>
  </template>
  
  <script>
  export default {
    name: 'TruncatedText',
    props: {
      text: {
        type: String,
        default: "N/A"

      },
      maxLength: {
        type: Number,
        default: 100
      }
    },
    computed: {
      truncated() {
        if (this.text === undefined || this.text === null || this.text.length === 0) {
          return "N/A";
        }
        if (this.text.length > this.maxLength) {
          return this.text.slice(0, this.maxLength) + '...';
        }
        return this.text;
      }
    }
  };
  </script>
  
  <style scoped>
  .truncated-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  </style>
  