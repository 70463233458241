<template>
  <div v-if="loading">
    <CircularLoadingBar></CircularLoadingBar>
  </div>
  <div v-else>
    <div class="card">
      <!--begin::Body-->
      <div class="card-body py-0">
        <div class="card-header border-0" style="background-color: transparent">
          <!--begin::Card title-->
          <div class="card-title">
            <!--begin::Search-->
            <div class="d-flex align-items-center position-relative my-1">
              <input
                type="text"
                v-model="search_term"
                @input="search_reports()"
                class="form-control form-control-solid w-250px ps-15"
                placeholder="Search Users"
                style="border: 1px solid grey"
              />
            </div>
            <!--end::Search-->
          </div>
          <!--begin::Card toolbar-->
          <div class="card-toolbar">
            <!--begin::Toolbar-->
            <div class="d-flex justify-content-end">
              <!--begin::Add Users -->
              <button
                type="button"
                class="btn btn-primary"
                @click="new_users_page()"
              >
                New User
              </button>
              <!--end::Add Users -->
            </div>
            <!--end::Toolbar-->
          </div>
          <!--end::Card toolbar-->
        </div>
        <div class="tab-content">
          <!--begin::Tap pane-->
          <div class="tab-pane fade active show">
            <!--begin::Table container-->
            <div>
              <!--begin::Table-->
              <table
                class="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4"
              >
                <!--begin::Table head-->
                <thead>
                  <tr class="border-0"></tr>
                </thead>
                <!--end::Table head-->
                <!--begin::Table body-->
                <tbody>
                  <tr>
                    <td>
                      <div class="align-top symbol symbol-45px me-2">
                        <span class="symbol-label"> # </span>
                      </div>
                    </td>

                    <td
                      v-for="label in column_labels" :key="label.id"
                      class="align-top text-dark fw-bold mb-1 fs-6"
                    >
                      {{ label }}
                    </td>
                  </tr>

                  <tr
                    v-for="(user, index) in filtered_users_data"
                    :key="index"
                  >
                    <td>
                      <div class="symbol symbol-45px me-2">
                        <span class="symbol-label">
                          {{ index + 1 }}
                        </span>
                      </div>
                    </td>

                    <td class="text-start">
                      <TruncatedText
                      :text="user['name']"
                      maxLength=50></TruncatedText>
                    </td>


                    <td class="text-start">
                      <TruncatedText
                      :text="user['email']"
                      maxLength=50></TruncatedText>
                    </td>


                    <td class="text-start">
                      <TruncatedText
                      :text="user['role']"
                      maxLength=50></TruncatedText>
                    </td>

                    <!-- <td class="text-start">
                      <TruncatedText
                      :text="user['sector']"
                      maxLength=50></TruncatedText>
                    </td> -->


                    <td class="text-start">
                      <TruncatedText
                      :text="user['description']"
                      maxLength=50></TruncatedText>
                    </td>

                    <td class="text-start">
                      <TruncatedText
                      :text="formatTime(user['last_login'])"
                      maxLength=50></TruncatedText>
                    </td>


                    <td class="text-start">
                      <div class="d-flex align-items-center">
                        <i
                          class="bi bi-pencil-square mx-2 custom-icon"
                          style="cursor: pointer"
                          title="Edit User"
                          @click="users_edit_page(user['id'])"
                        ></i>
                        <i
                          class="bi bi-trash mx-2 custom-icon"
                          style="cursor: pointer"
                          title="Delete User"
                          @click="delete_user(user['id'])"
                        ></i>
                      </div>
                    </td>
                  </tr>
                </tbody>
                <!--end::Table body-->
              </table>
            </div>
            <!--end::Table-->
          </div>
          <!--end::Tap pane-->
        </div>
      </div>
      <!--end::Body-->
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { getData, deleteData } from "@/core/services/ApiService";
import CircularLoadingBar from "@/components/widgets/circularloadingbar/CircularLoadingBar.vue";
import TruncatedText from "@/components/TruncatedText.vue";
export default defineComponent({
  name: "Users",
  components: {
    CircularLoadingBar,
    TruncatedText
  },
  data() {
    return {
      loading: true,
      UsersListData: [],
      search_term: "",
      column_labels: [
        "Name",
        "Email",
        "Role",
        "Description",
        "Last Login",
        "Action",
      ],
      filtered_users_data: [],
    };
  },
  setup() {
    setCurrentPageBreadcrumbs("Users");
  },

  methods: {
    async getData() {
      this.UsersListData = await getData(this.$router, "users");
      this.filtered_users_data = this.UsersListData;
    },

    async delete_user(id) {
      const idToDelete = id;
      await deleteData(this.$router, "users", idToDelete, "Deleting User...");

      for (let i = 0; i < this.UsersListData.length; i++) {
        if (this.UsersListData[i]["id"] === idToDelete) {
          this.UsersListData.splice(i, 1);
        }
      }
      this.filtered_users_data = this.UsersListData;
    },

    search_reports() {
      const inputValue = this.search_term.toLowerCase();
      this.filtered_users_data = this.UsersListData.filter((option) =>
        String(option["name"]).toLowerCase().includes(inputValue)
      );
    },

    users_edit_page(id) {
      this.$router.push({
        name: "edit_user",
        params: { id: id },
      });
    },

    new_users_page() {
      this.$router.push({ name: "add_user" });
    },

    formatTime(timeString) {
      const date = new Date(timeString);
      const formattedTime = date.toLocaleString();
      return formattedTime;
    },
  },
  async mounted() {
    await this.getData();
    this.loading = false;
  },
});
</script>