<template>
  <div v-if="loading">
    <CircularLoadingBar></CircularLoadingBar>
  </div>

  <div v-else class="container-fluid add-project">
    <form>
      <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12">
          <label for="name">Name</label>
          <input
            v-model="departmentsData.name"
            id="name"
            class="form-control"
            type="text"
            placeholder="Enter Title"
          />
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12">
          <label for="nameInArabic">Name (In Arabic)</label>
          <input
            v-model="departmentsData.name_ar"
            id="nameInArabic"
            class="form-control"
            type="text"
            placeholder="Enter Title (in arabic)"
          />
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-lg-6 col-md-6 col-sm-12">
          <label for="description">Description</label>
          <input
            v-model="departmentsData.description"
            id="description"
            class="form-control"
            type="text"
            placeholder="Enter Description"
          />
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12">
          <label for="descriptionInArabic">Description (In Arabic)</label>
          <input
            v-model="departmentsData.description_ar"
            id="descriptionInArabic"
            class="form-control"
            type="text"
            placeholder="Enter Description (in arabic)"
          />
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-lg-6 col-md-6 col-sm-12">
          <label for="description">No Observation Text</label>
          <input
            v-model="departmentsData.no_observation_description"
            id="description"
            class="form-control"
            type="text"
            placeholder="Enter No Observation Text"
          />
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12">
          <label for="descriptionInArabic">No Observation Text (In Arabic)</label>
          <input
            v-model="departmentsData.no_observation_description_ar"
            id="descriptionInArabic"
            class="form-control"
            type="text"
            placeholder="Enter No Observation Text (in arabic)"
          />
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-lg-6 col-md-6 col-sm-12">
          <label for="departmentType">Department Type</label>
          <SearchableDropdown
            id="departmentType"
            class=""
            placeholder="Select Department"
            :options="departmentTypeOptions"
            v-model="departmentsData.department_type"
          ></SearchableDropdown>
        </div>
        <div
          v-if="departmentsData.department_type === 'Sub-department / قسم فرعى'"
          class="col-lg-6 col-md-6 col-sm-12"
        >
          <label for="parent">Parent</label>
          <SearchableDropdown
            id="parent"
            class=""
            placeholder="Select Parent"
            :options="parentOptions"
            v-model="departmentsData.parent"
          ></SearchableDropdown>
        </div>
        <div v-else></div>
      </div>
      <div class="mt-4" style="text-align: end">
        <button
          class="mx-2 btn btn-primary submit"
          type="submit"
          @click="checkForm()"
        >
          Submit
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { postData, updateData, getData } from "@/core/services/ApiService";
import CircularLoadingBar from "@/components/widgets/circularloadingbar/CircularLoadingBar.vue";
import SearchableDropdown from "@/components/SimpleSelect.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";

export default defineComponent({
  name: "AuditDepartmentForm",
  props: {
    id: {
      type: String,
      default: "",
    },
  },
  components: {
    SearchableDropdown,
    CircularLoadingBar,
  },
  data() {
    return {
      loading: true,
      departmentsData: {
        name: "",
        name_ar: "",
        description: "",
        description_ar: "",
        no_observation_description: "",
        no_observation_description_ar: "",
        department_type: "",
        parent: "",
        created_at: "",
        updated_at: "",
      },
      departmentTypeOptions: [
        { key: "Department / قسم", value: "Department / قسم" },
        {
          key: "Sub-department / قسم فرعى",
          value: "Sub-department / قسم فرعى",
        },
      ],
      parentOptions: [],
    };
  },
  methods: {
    async createData() {
      const endpoint = "departments";
      this.departmentsData.created_at = new Date(
        Date.now() + 3 * 60 * 60 * 1000
      )
        .toISOString()
        .split("Z")[0];
      this.departmentsData.updated_at = this.departmentsData.created_at;
      const json_data = JSON.stringify(this.departmentsData);
      const response = await postData(this.$router, endpoint, json_data, "Creating Department...");
      if (response.ok) {
        Swal.fire({
          text: "Department Created Successfully",
          icon: "success",
          buttonsStyling: false,
          confirmButtonText: "Ok, got it!",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        }).then((result) => {
          if (result.isConfirmed) {
            this.$router.push({ name: "AuditDepartments" });
          }
        });
      }
    },
    async getData(id) {
      const endpoint = `departments/${id}`;
      this.departmentsData = await getData(this.$router, endpoint);
    },
    async updateData(id) {
      const endpoint = "departments";
      this.departmentsData.updated_at = new Date(
        Date.now() + 3 * 60 * 60 * 1000
      )
        .toISOString()
        .split("Z")[0];
      const json_data = JSON.stringify(this.departmentsData);
      const response = await updateData(this.$router, endpoint, json_data, id, "Updating Department...");
      if (response.ok) {
        Swal.fire({
          text: "Department Updated Successfully",
          icon: "success",
          buttonsStyling: false,
          confirmButtonText: "Ok, got it!",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        }).then((result) => {
          if (result.isConfirmed) {
            this.$router.push({ name: "AuditDepartments" });
          }
        });
      }
    },
    async checkForm() {
      let errors = "";

      if (!this.departmentsData.name) {
        errors += "Name is required. \n";
      }
      if (!this.departmentsData.name_ar) {
        errors += "Name (In Arabic) is required. \n";
      }
      if (!this.departmentsData.description) {
        errors += "Description is required. \n";
      }
      if (!this.departmentsData.description_ar) {
        errors += "Description (In Arabic) is required. \n";
      }
      if (!this.departmentsData.no_observation_description) {
        errors += "No Observation Description is required. \n";
      }
      if (!this.departmentsData.no_observation_description_ar) {
        errors += "No Observation Description (In Arabic) is required. \n";
      }
      if (!this.departmentsData.department_type) {
        errors += "Department Type is required. \n";
      }

      if (
        this.departmentsData.department_type === "Sub-department / قسم فرعى"
      ) {
        if (!this.departmentsData.parent) {
          errors += "Parent is required for Sub-department. \n";
        }
      }

      if (errors === "") {
        if (this.$props.id) {
          await this.updateData(this.$props.id);
        } else {
          await this.createData();
        }
      } else {
        Swal.fire({
          text: errors,
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Ok, got it!",
          customClass: {
            confirmButton: "btn btn-primary",
            content: "text-left",
          },
        });
      }
    },
    async fetchAllDepartments() {
      const response = await getData(this.$router, "departments");
      const departments = response.filter(
        (department) => department.department_type === "Department / قسم"
      );
      this.parentOptions = departments.map((department) => ({
        key: department.id,
        value: department.name,
      }));
    },
  },
  watch: {
    "departmentsData.department_type": function (newVal) {
      if (newVal === "Sub-department / قسم فرعى") {
        this.departmentsData.parent = "";
      }
    },
  },
  async mounted() {
    if (this.$props.id) {
      setCurrentPageBreadcrumbs("Update Department");
      await this.getData(this.$props.id);
    } else {
      setCurrentPageBreadcrumbs("Create Department");
    }
    await this.fetchAllDepartments();
    this.loading = false;
  },
});
</script>