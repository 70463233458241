<template>
  <div v-if="loading">
    <CircularLoadingBar></CircularLoadingBar>
  </div>
  <div v-else>
    <div class="card">
      <!--begin::Body-->
      <div class="card-body py-0">
        <div class="card-header border-0" style="background-color: transparent">
          <!--begin::Card title-->
          <div class="card-title">
            <!--begin::Search-->
            <div class="d-flex align-items-center position-relative my-1">
              <input
                type="text"
                v-model="search_term"
                @input="search_reports()"
                class="form-control form-control-solid w-250px ps-15"
                placeholder="Search Scholar"
                style="border: 1px solid grey"
              />
            </div>
            <!--end::Search-->
          </div>
          <!--begin::Card title-->
          <!--begin::Card toolbar-->
          <div class="card-toolbar">
            <!--begin::Toolbar-->
            <div class="d-flex justify-content-end">
              <!--begin:: Add Scholar -->
              <button
                type="button"
                class="btn btn-primary"
                @click="new_scholar_page()"
              >
                New Scholar
              </button>
              <!--end::Add Scholar -->
            </div>
            <!--end::Toolbar-->
          </div>
          <!--end::Card toolbar-->
        </div>
        <div class="tab-content">
          <!--begin::Tap pane-->
          <div class="tab-pane fade active show" id="kt_table_widget_5_tab_1">
            <!--begin::Table container-->
            <div>
              <!--begin::Table-->
              <table
                class="table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4"
                style="width: 100%"
              >
                <!--begin::Table head-->
                <thead>
                  <tr class="border-0"></tr>
                </thead>
                <!--end::Table head-->
                <!--begin::Table body-->
                <tbody>
                  <tr>
                    <td>
                      <div class="align-top symbol symbol-45px me-2">
                        <span class="symbol-label"> # </span>
                      </div>
                    </td>

                    <td
                      class="align-top text-dark fw-bold text-hover-primary mb-1 fs-6"
                    >
                      First Name
                    </td>
                    <td
                      class="align-top text-dark fw-bold text-hover-primary mb-1 fs-6"
                    >
                      First Name (In Arabic)
                    </td>
                    <td
                      class="align-top text-dark fw-bold text-hover-primary mb-1 fs-6"
                    >
                      Last Name
                    </td>
                    <td
                      class="align-top text-dark fw-bold text-hover-primary mb-1 fs-6"
                    >
                      Last Name (In Arabic)
                    </td>

                    <td
                      class="align-top text-dark fw-bold text-hover-primary mb-1 fs-6"
                    >
                      Email
                    </td>

                    <td
                      class="align-top text-dark fw-bold text-hover-primary mb-1 fs-6"
                    >
                      Action
                    </td>
                  </tr>

                  <tr
                    v-for="(scholar, index) in filtered_scholars_Data"
                    :key="index"
                  >
                    <td>
                      <div class="symbol symbol-45px me-2">
                        <span class="symbol-label">
                          {{ index + 1 }}
                        </span>
                      </div>
                    </td>

                    <td class="text-start">
                      <TruncatedText
                        :text="scholar['first_name']"
                        maxLength=30
                      ></TruncatedText>
                    </td>

                    <td class="text-start">
                      <TruncatedText
                        :text="scholar['first_name_ar']"
                        maxLength=30
                      ></TruncatedText>
                    </td>

                    <td class="text-start">
                      <TruncatedText
                        :text="scholar['last_name']"
                        maxLength=30
                      ></TruncatedText>
                    </td>

                    <td class="text-start">
                      <TruncatedText
                        :text="scholar['last_name_ar']"
                        maxLength=30
                      ></TruncatedText>
                    </td>

                    <td class="text-start">
                      <TruncatedText
                        :text="scholar['email']"
                        maxLength=50
                      ></TruncatedText>
                    </td>
                    <td class="text-start">
                      <div class="d-flex align-items-center">
                        <i
                          class="bi bi-pencil-square mx-2 custom-icon"
                          style="cursor: pointer"
                          title="Edit Reference"
                          @click="scholar_edit_page(index)"
                        ></i>
                        <i
                          class="bi bi-trash mx-2 custom-icon"
                          style="cursor: pointer"
                          title="Delete Reference"
                          @click="deleteData(scholar['id'])"
                        ></i>
                      </div>
                    </td>
                  </tr>
                </tbody>
                <!--end::Table body-->
              </table>
            </div>
            <!--end::Table-->
          </div>
          <!--end::Tap pane-->
        </div>
      </div>
      <!--end::Body-->
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { getData, deleteData } from "@/core/services/ApiService";
import CircularLoadingBar from "@/components/widgets/circularloadingbar/CircularLoadingBar.vue";
import TruncatedText from "@/components/TruncatedText.vue";

export default defineComponent({
  name: "AuditScholars",
  components: {
    CircularLoadingBar,
    TruncatedText
  },
  data() {
    return {
      loading: true,
      scholarsData: [],
      search_term: "",
      filtered_scholars_Data: [],
    };
  },
  setup() {
    setCurrentPageBreadcrumbs("Scholars");
  },

  methods: {
    async getData() {
      this.scholarsData = await getData(this.$router, "scholars");
      this.filtered_scholars_Data = this.scholarsData;
    },

    async deleteData(id) {
      const idToDelete = id;
      await deleteData(this.$router, "scholars", idToDelete, "Deleting Scholar...");
      for (let i = 0; i < this.scholarsData.length; i++) {
        if (this.scholarsData[i]["id"] === idToDelete) {
          this.scholarsData.splice(i, 1);
        }
      }
      this.filtered_scholars_Data = this.scholarsData;
    },

    search_reports() {
      const inputValue = this.search_term.toLowerCase();
      this.filtered_scholars_Data = this.scholarsData.filter((option) =>
        String(option["first_name"]).toLowerCase().includes(inputValue)
      );
    },

    scholar_edit_page(index) {
      const id = this.scholarsData[index]["id"];
      this.$router.push({
        name: "AuditScholarForm",
        params: { id: id },
      });
    },

    new_scholar_page() {
      this.$router.push({ name: "AuditScholarForm" });
    },
  },
  async mounted() {
    await this.getData();
    this.loading = false;
  },
});
</script>
