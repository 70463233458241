<template>
  <div v-if="loading">
    <CircularLoadingBar></CircularLoadingBar>
  </div>

  <div v-else class="container-fluid add-project">
    <form>
      <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12">
          <label for="name">Name</label>
          <input v-model="GuidelineData.name" id="name" class="form-control" type="text"
            placeholder="Enter Name" />
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12">
          <label for="heading">Heading</label>
          <input v-model="GuidelineData.heading" id="heading" class="form-control" type="text"
            placeholder="Enter Heading" />
        </div>
      </div>


   
      <div class="mt-4" style="text-align: end">
        <!-- <button class="mx-2 btn btn-primary submit" type="submit">{{ ButtonText }}</button> -->
        <button
          class="mx-2 btn btn-primary submit"
          type="submit"
          @click="this.checkForm()"
        >
          Submit
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { defineComponent } from "vue"; // Import defineComponent from Vue
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { postData, updateData, getData } from "@/core/services/ApiService";
import SearchableDropdown from "@/components/SimpleSelect.vue";
import CircularLoadingBar from "@/components/widgets/circularloadingbar/CircularLoadingBar.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";

export default defineComponent({
  name: "GuidelineForm",
  props: {
    id: {
      type: String,
      default: "",
    },
  },
  components: {
    SearchableDropdown,
    CircularLoadingBar,
  },
  data() {
    return {
      loading: true,
      GuidelineData: {
        name: "",
        heading: "",

      },
    };
  },
  methods: {
    async createData() {
      const endpoint = "guidelines";
      this.GuidelineData.link = "N/A"
      const json_data = JSON.stringify(this.GuidelineData);
      const response = await postData(this.$router, 
        endpoint,
        json_data,
        "Creating Guideline..."
      );
      if (response.ok) {
        Swal.fire({
          text: "Guideline Created Successfully",
          icon: "success",
          buttonsStyling: false,
          confirmButtonText: "Ok, got it!",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        }).then((result) => {
          if (result.isConfirmed) {
            this.$router.push({ name: "Guidelines" });
          }
        });
      }
    },
    async getData(id) {
      const url = `guidelines/${id}`;
      this.GuidelineData = await getData(this.$router, url);
    },
    async updateData(id) {
      const endpoint = `guidelines`;
      const json_data = JSON.stringify(this.GuidelineData);
      const response = await updateData(this.$router, endpoint, json_data, id, "Updating Guideline...");
      if (response.ok) {
        Swal.fire({
          text: "Guideline Updated Successfully",
          icon: "success",
          buttonsStyling: false,
          confirmButtonText: "Ok, got it!",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        }).then((result) => {
          if (result.isConfirmed) {
            this.$router.push({ name: "Guidelines" });
          }
        });
      }
    },
    async checkForm() {
      let errors = "";

      if (!this.GuidelineData.name) {
        errors += "Name is required. \n";
      }

      if (!this.GuidelineData.heading) {
        errors += "Heading is required. \n";
      }
 

      if (errors === "") {
        if (this.$props.id) {
          await this.updateData(this.$props.id);
        } else {
          await this.createData();
        }
      } else {
        Swal.fire({
          text: errors, 
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Ok, got it!",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
      }
    },
  },
  async mounted() {
    if (this.$props.id) {
      setCurrentPageBreadcrumbs("Update Guideline");
      await this.getData(this.$props.id);
    } else {
      setCurrentPageBreadcrumbs("Create Guideline");
    }
    this.loading = false;
  },
});
</script>