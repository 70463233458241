<template>
  <div v-if="loading">
    <CircularLoadingBar></CircularLoadingBar>
  </div>
  <div v-else>
    <div class="card">
      <!--begin::Body-->
      <div class="card-body py-0">
        <div class="card-header border-0" style="background-color: transparent">
          <!--begin::Card title-->
          <div class="card-title">
            <!--begin::Search-->
            <div class="d-flex align-items-center position-relative my-1">
              <input
                type="text"
                v-model="search_term"
                @input="search_reports()"
                class="form-control form-control-solid w-250px ps-15"
                placeholder="Search Shariyah Company"
                style="border: 1px solid grey"
              />
            </div>
            <!--end::Search-->
          </div>
          <!--begin::Card title-->
          <!--begin::Card toolbar-->
          <div class="card-toolbar">
            <!--begin::Toolbar-->
            <div class="d-flex justify-content-end">
              <!--begin::Shariyah Company -->
              <button
                type="button"
                class="btn btn-primary"
                @click="new_shariyah_company_page()"
              >
                New Shariyah Company
              </button>
              <!--end::Add Shariyah Company -->
            </div>
            <!--end::Toolbar-->
          </div>
          <!--end::Card toolbar-->
        </div>
        <div class="tab-content">
          <!--begin::Tap pane-->
          <div class="tab-pane fade active show" id="kt_table_widget_5_tab_1">
            <!--begin::Table container-->
            <div>
              <!--begin::Table-->
              <table
                class="table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4"
                style="width: 100%"
              >
                <!--begin::Table head-->
                <thead>
                  <tr class="border-0"></tr>
                </thead>
                <!--end::Table head-->
                <!--begin::Table body-->
                <tbody>
                  <tr>
                    <td>
                      <div class="align-top symbol symbol-45px me-2">
                        <span class="symbol-label"> # </span>
                      </div>
                    </td>

                    <td
                      class="align-top text-dark fw-bold text-hover-primary mb-1 fs-6"
                    >
                      Name
                    </td>
                    <td
                      class="align-top text-dark fw-bold text-hover-primary mb-1 fs-6"
                    >
                      Name (In Arabic)
                    </td>
                    <td
                      class="align-top text-dark fw-bold text-hover-primary mb-1 fs-6"
                    >
                      Code
                    </td>
                    <td
                      class="align-top text-dark fw-bold text-hover-primary mb-1 fs-6"
                    >
                      Code (In Arabic)
                    </td>

                    <td
                      class="align-top text-dark fw-bold text-hover-primary mb-1 fs-6"
                    >
                      Action
                    </td>
                  </tr>

                  <tr
                    v-for="(
                      shariyahCompany, index
                    ) in filtered_ShariyahCompanies_Data"
                    :key="index"
                  >
                    <td>
                      <div class="symbol symbol-45px me-2">
                        <span class="symbol-label">
                          {{ index + 1 }}
                        </span>
                      </div>
                    </td>

                    <td class="text-start">
                      <TruncatedText
                        :text="shariyahCompany['name']"
                        maxLength=40
                      ></TruncatedText>
                    </td>

                    <td class="text-start">
                      <TruncatedText
                        :text="shariyahCompany['name_ar']"
                        maxLength=40
                      ></TruncatedText>
                    </td>

                    <td class="text-start">
                      <span>{{
                        shariyahCompany["code"]
                      }}</span>
                    </td>

                    <td class="text-start">
                      <span>{{
                        shariyahCompany["code_ar"]
                      }}</span>
                    </td>
                    <td class="text-start">
                      <div class="d-flex align-items-center">
                        <i
                          class="bi bi-pencil-square mx-2 custom-icon"
                          style="cursor: pointer"
                          title="Edit Reference"
                          @click="shariyah_company_edit_page(index)"
                        ></i>
                        <i
                          class="bi bi-trash mx-2 custom-icon"
                          style="cursor: pointer"
                          title="Delete Reference"
                          @click="deleteData(shariyahCompany['id'])"
                        ></i>
                      </div>
                    </td>
                  </tr>
                </tbody>
                <!--end::Table body-->
              </table>
            </div>
            <!--end::Table-->
          </div>
          <!--end::Tap pane-->
        </div>
      </div>
      <!--end::Body-->
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { getData, deleteData } from "@/core/services/ApiService";
import CircularLoadingBar from "@/components/widgets/circularloadingbar/CircularLoadingBar.vue";
import TruncatedText from "@/components/TruncatedText.vue";

export default defineComponent({
  name: "ShariyahCompanies",
  components: {
    CircularLoadingBar,
    TruncatedText,
  },
  data() {
    return {
      loading: true,
      shariyahCompaniesData: [],
      search_term: "",
      filtered_ShariyahCompanies_Data: [],
    };
  },
  setup() {
    setCurrentPageBreadcrumbs("Shariyah Companies");
  },

  methods: {
    async getData() {
      this.shariyahCompaniesData = await getData(this.$router, "shariyah_companies");
      this.filtered_ShariyahCompanies_Data = this.shariyahCompaniesData;
    },
    async deleteData(id) {
      const idToDelete = id;
      await deleteData(this.$router, 
        "shariyah_companies",
        idToDelete,
        "Deleting Shariyah Company..."
      );
      for (let i = 0; i < this.shariyahCompaniesData.length; i++) {
        if (this.shariyahCompaniesData[i]["id"] === idToDelete) {
          this.shariyahCompaniesData.splice(i, 1);
        }
      }
      this.filtered_ShariyahCompanies_Data = this.shariyahCompaniesData;
    },
    search_reports() {
      const inputValue = this.search_term.toLowerCase();
      this.filtered_ShariyahCompanies_Data = this.shariyahCompaniesData.filter(
        (option) => String(option["name"]).toLowerCase().includes(inputValue)
      );
    },
    shariyah_company_edit_page(index) {
      const id = this.shariyahCompaniesData[index]["id"];
      this.$router.push({
        name: "ShariyahCompaniesForm",
        params: { id: id },
      });
    },
    new_shariyah_company_page() {
      this.$router.push({ name: "ShariyahCompaniesForm" });
    },
  },
  async mounted() {
    await this.getData();
    this.loading = false;
  },
});
</script>
