<template>
  <div v-if="loading">
    <CircularLoadingBar></CircularLoadingBar>
  </div>
  <div v-else>
    <div class="card">
      <!--begin::Body-->
      <div class="card-body py-0">
        <div class="card-header border-0" style="background-color: transparent">
          <!--begin::Card title-->
          <div class="card-title">
            <!--begin::Search-->
            <div class="d-flex align-items-center position-relative my-1">
              <input
                type="text"
                v-model="search_term"
                @input="search_reports()"
                class="form-control form-control-solid w-250px ps-15"
                placeholder="Search Audit Observations"
                style="border: 1px solid grey"
              />
            </div>
            <!--end::Search-->
          </div>
          <!--begin::Card title-->
          <!--begin::Card toolbar-->
          <div class="card-toolbar">
            <!--begin::Toolbar-->
            <div class="d-flex justify-content-end">
              <!--begin::Add Audit Observation-->
              <button
                type="button"
                class="btn btn-primary"
                @click="new_audit_observation_page()"
              >
                New Audit Observation
              </button>
              <!--end::Add Audit Observation-->
            </div>
            <!--end::Toolbar-->
          </div>
          <!--end::Card toolbar-->
        </div>
        <div class="tab-content">
          <!--begin::Tap pane-->
          <div class="tab-pane fade active show" id="kt_table_widget_5_tab_1">
            <!--begin::Table container-->
            <div class="table-responsive">
              <!--begin::Table-->
              <table
                class="table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4"
                style="width: 100%"
              >
                <!--begin::Table head-->
                <thead>
                  <tr class="border-0"></tr>
                </thead>
                <!--end::Table head-->
                <!--begin::Table body-->
                <tbody>
                  <tr>
                    <td>
                      <div class="symbol symbol-45px me-2">
                        <span class="symbol-label"> # </span>
                      </div>
                    </td>

                    <td
                      v-for="label in column_labels"
                      class="text-dark fw-bold mb-1 fs-6"
                    >
                      {{ label }}
                    </td>
                  </tr>

                  <tr
                    v-for="(observation, index) in filtered_audit_reports"
                    :key="index"
                  >
                    <td>
                      <div class="symbol symbol-45px me-2">
                        <span class="symbol-label">
                          {{ index + 1 }}
                        </span>
                      </div>
                    </td>

                    <td class="text-start">
                      <TruncatedText
                        :text="observation['title']"
                        maxLength=24
                      >
                      </TruncatedText>
                    </td>

                    <td class="text-start">
                      <TruncatedText
                        :text="observation['title_ar']"
                        maxLength=24
                      >
                      </TruncatedText>
                    </td>

                    <td class="text-start">
                      <TruncatedText
                        :text="observation['description']"
                        maxLength=48
                      >
                      </TruncatedText>
                    </td>

                    <td class="text-start">
                      <TruncatedText
                        :text="observation['description_ar']"
                        maxLength=48
                      >
                      </TruncatedText>
                    </td>

                    <td class="text-start">
                      <div class="d-flex align-items-center">
                        <i
                          class="bi bi-pencil-square mx-2 custom-icon"
                          style="cursor: pointer"
                          title="Edit Observation"
                          @click="audit_observation_edit_page(index)"
                        ></i>
                        <i
                          class="bi bi-trash mx-2 custom-icon"
                          style="cursor: pointer"
                          title="Delete Observation"
                          @click="deleteData(observation['id'])"
                        ></i>
                      </div>
                    </td>
                  </tr>
                </tbody>
                <!--end::Table body-->
              </table>
            </div>
            <!--end::Table-->
          </div>
          <!--end::Tap pane-->
        </div>
      </div>
      <!--end::Body-->
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { getData, deleteData } from "@/core/services/ApiService";
import CircularLoadingBar from "@/components/widgets/circularloadingbar/CircularLoadingBar.vue";
import TruncatedText from "@/components/TruncatedText.vue";

export default defineComponent({
  name: "AuditObservations",
  components: {
    CircularLoadingBar,
    TruncatedText,
  },
  data() {
    return {
      loading: true,
      column_labels: [
        "Title",
        "Title (In Arabic)",
        "Description",
        "Description (In Arabic)",
        "Actions",
      ],
      AuditObservationsData: [],
      search_term: "",
      filtered_audit_reports: [],
    };
  },
  setup() {
    setCurrentPageBreadcrumbs("Audit Observations");
  },

  methods: {
    async getData() {
      this.AuditObservationsData = await getData(this.$router, "audit_observations");
      this.filtered_audit_reports = this.AuditObservationsData;
    },
    async deleteData(id) {
      const idToDelete = id;
      await deleteData(this.$router, 
        "audit_observations",
        idToDelete,
        "Deleting Observation..."
      );
      for (let i = 0; i < this.AuditObservationsData.length; i++) {
        if (this.AuditObservationsData[i]["id"] === idToDelete) {
          this.AuditObservationsData.splice(i, 1);
        }
      }
      this.filtered_audit_reports = this.AuditObservationsData;
    },
    search_reports() {
      const inputValue = this.search_term.toLowerCase();
      this.filtered_audit_reports = this.AuditObservationsData.filter(
        (option) => String(option["title"]).toLowerCase().includes(inputValue)
      );
    },
    audit_observation_edit_page(index) {
      const id = this.AuditObservationsData[index]["id"];
      this.$router.push({
        name: "AuditObservationForm",
        params: { id: id },
      });
    },
    new_audit_observation_page() {
      this.$router.push({ name: "AuditObservationForm" });
    },
  },
  async mounted() {
    await this.getData();
    this.loading = false;
  },
});
</script>
