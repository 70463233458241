<template>
  <div v-if="loading">
    <CircularLoadingBar></CircularLoadingBar>
  </div>

  <div v-else class="container-fluid add-project">
    <form>
      <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12">
          <label for="Name">Sector Name</label>
          <input
            v-model="SectorListData.name"
            id="Name"
            class="form-control"
            type="text"
            placeholder="Enter Sector Name"
          />
        </div>
      </div>


      <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12">
          <div class="form-check form-switch mt-2">
            <input class="form-check-input" type="checkbox" id="status"
              v-model="SectorListData.status" :true-value="1" :false-value="0" />
            <label class="form-check-label" for="status">Sector Status</label>
          </div>
        </div>
       
      </div>



   
      <div class="mt-4" style="text-align: end">
        <!-- <button class="mx-2 btn btn-primary submit" type="submit">{{ ButtonText }}</button> -->
        <button
          class="mx-2 btn btn-primary submit"
          type="submit"
          @click="this.checkForm()"
        >
          Submit
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { defineComponent } from "vue"; // Import defineComponent from Vue
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { postData, updateData, getData } from "@/core/services/ApiService";
import SearchableDropdown from "@/components/SimpleSelect.vue";
import CircularLoadingBar from "@/components/widgets/circularloadingbar/CircularLoadingBar.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";

export default defineComponent({
  name: "SectorListForm",
  props: {
    id: {
      type: String,
      default: "",
    },
  },
  components: {
    SearchableDropdown,
    CircularLoadingBar,
  },
  data() {
    return {
      loading: true,
      SectorListData: {
        name: "",
        status: 1,

      },
    };
  },
  methods: {
    async createData() {
      const endpoint = "sectors";
      const json_data = JSON.stringify(this.SectorListData);
      const response = await postData(this.$router, 
        endpoint,
        json_data,
        "Creating Sector..."
      );
      if (response.ok) {
        Swal.fire({
          text: "Sector Created Successfully",
          icon: "success",
          buttonsStyling: false,
          confirmButtonText: "Ok, got it!",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        }).then((result) => {
          if (result.isConfirmed) {
            this.$router.push({ name: "SectorList" });
          }
        });
      }
    },
    async getData(id) {
      const url = `sectors/${id}`;
      this.SectorListData = await getData(this.$router, url);
    },
    async updateData(id) {
      const endpoint = `sectors`;
      const json_data = JSON.stringify(this.SectorListData);
      const response = await updateData(this.$router, endpoint, json_data, id, "Updating Sector...");
      if (response.ok) {
        Swal.fire({
          text: "Sector Updated Successfully",
          icon: "success",
          buttonsStyling: false,
          confirmButtonText: "Ok, got it!",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        }).then((result) => {
          if (result.isConfirmed) {
            this.$router.push({ name: "SectorList" });
          }
        });
      }
    },
    async checkForm() {
      let errors = "";

      if (!this.SectorListData.name) {
        errors += "Sector name is required. \n";
      }
 

      if (errors === "") {
        if (this.$props.id) {
          await this.updateData(this.$props.id);
        } else {
          await this.createData();
        }
      } else {
        Swal.fire({
          text: errors, 
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Ok, got it!",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
      }
    },
  },
  async mounted() {
    if (this.$props.id) {
      setCurrentPageBreadcrumbs("Update Sectors");
      await this.getData(this.$props.id);
    } else {
      setCurrentPageBreadcrumbs("Create Sectors");
    }
    this.loading = false;
  },
});
</script>