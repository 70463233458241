<template>
  <div v-if="loading">
    <CircularLoadingBar></CircularLoadingBar>
  </div>

  <div v-else class="container-fluid add-project">
    <form>
      <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12">
          <label for="title">Title</label>
          <input
            v-model="AuditObservationFormData.title"
            id="title"
            class="form-control"
            type="text"
            placeholder="Enter title"
          />
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12">
          <label>Title (In Arabic)</label>
          <input
            v-model="AuditObservationFormData.title_ar"
            class="form-control"
            type="text"
            placeholder="Enter title (in arabic)"
          />
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-lg-6 col-md-6 col-sm-12">
          <label>Description</label>
          <textarea
            v-model="AuditObservationFormData.description"
            class="form-control"
            type="text"
            placeholder="Enter description"
          ></textarea>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12">
          <label>Description (In Arabic)</label>
          <textarea
            v-model="AuditObservationFormData.description_ar"
            class="form-control"
            type="text"
            placeholder="Enter description (in arabic)"
          ></textarea>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-lg-6 col-md-6 col-sm-12">
          <label>Impact</label>
          <textarea
            v-model="AuditObservationFormData.impact"
            class="form-control"
            type="text"
            placeholder="Enter impact"
          ></textarea>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12">
          <label>Impact (In Arabic)</label>
          <textarea
            v-model="AuditObservationFormData.impact_ar"
            class="form-control"
            type="text"
            placeholder="Enter impact (in arabic)"
          ></textarea>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-lg-6 col-md-6 col-sm-12">
          <label>Recommendation</label>
          <textarea
            v-model="AuditObservationFormData.recommendation"
            class="form-control"
            type="text"
            placeholder="Enter recommendation"
          ></textarea>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12">
          <label>Recommendation (In Arabic)</label>
          <textarea
            v-model="AuditObservationFormData.recommendation_ar"
            class="form-control"
            type="text"
            placeholder="Enter recommendation (in arabic)"
          ></textarea>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-lg-6 col-md-6 col-sm-12">
          <label>Root Cause</label>
          <textarea
            v-model="AuditObservationFormData.root_cause"
            class="form-control"
            type="text"
            placeholder="Enter root cause"
          ></textarea>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12">
          <label>Root Cause (In Arabic)</label>
          <textarea
            v-model="AuditObservationFormData.root_cause_ar"
            class="form-control"
            type="text"
            placeholder="Enter root cause (in arabic)"
          ></textarea>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-lg-6 col-md-6 col-sm-12">
          <label>Select Reference</label>
          <SearchableDropdown
            id="reference"
            class=""
            placeholder="Select Reference"
            :options="ReferenceOptions"
            v-model="selectedReference"
            @keydown.enter.prevent="addToReferences"
          ></SearchableDropdown>
          <button @click.prevent="addToReferences" class="btn btn-primary my-3">
            Add
          </button>
          <button @click.prevent="deleteLastItem" class="btn btn-danger m-3">
            Remove
          </button>
        </div>

        <div class="col-lg-6 col-md-6 col-sm-12">
          <label>References</label>
          <textarea
            disabled
            rows="3"
            v-model="AuditObservationFormData.reference"
            class="form-control"
            type="text"
            placeholder="Enter References"
          ></textarea>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-lg-6 col-md-6 col-sm-12">
          <label>Select Reference (In Arabic)</label>
          <SearchableDropdown
            id="referencearabic"
            placeholder="Select Reference Arabic"
            :options="ReferenceOptionsArabic"
            v-model="selectedReferenceArabic"
            @keydown.enter.prevent="addToReferencesArabic"
          ></SearchableDropdown>
          <button
            @click.prevent="addToReferencesArabic"
            class="btn btn-primary my-3"
          >
            Add
          </button>
          <button
            @click.prevent="deleteLastItemArabic"
            class="btn btn-danger m-3"
          >
            Remove
          </button>
        </div>

        <div class="col-lg-6 col-md-6 col-sm-12">
          <label>References (In Arabic)</label>
          <textarea
            disabled
            rows="3"
            v-model="AuditObservationFormData.reference_ar"
            class="form-control"
            type="text"
            placeholder="Enter References"
          ></textarea>
        </div>
      </div>
      <div class="mt-4" style="text-align: end">
        <!-- <button class="mx-2 btn btn-primary submit" type="submit">{{ ButtonText }}</button> -->
        <button
          class="mx-2 btn btn-primary submit"
          type="submit"
          @click="checkForm()"
        >
          Submit
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { defineComponent } from "vue"; // Import defineComponent from Vue
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { postData, updateData, getData } from "@/core/services/ApiService";
import SearchableDropdown from "@/components/SimpleSelect.vue";
import CircularLoadingBar from "@/components/widgets/circularloadingbar/CircularLoadingBar.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";

export default defineComponent({
  name: "AuditObservationForm",
  props: {
    id: {
      type: String,
      default: "",
    },
  },
  components: {
    SearchableDropdown,
    CircularLoadingBar,
  },
  data() {
    return {
      loading: true,
      AuditObservationFormData: {
        title: "",
        title_ar: "",
        description: "",
        description_ar: "",
        impact: "",
        impact_ar: "",
        recommendation: "",
        recommendation_ar: "",
        root_cause: "",
        root_cause_ar: "",
        reference: "",
        reference_ar: "",
        created_at: "",
        updated_at: "",
      },
      ReferenceOptions: [],
      ReferenceOptionsArabic: [],
      selectedReference: "",
      selectedReferenceArabic: "",
      references: "",
    };
  },
  methods: {
    async createData() {
      this.AuditObservationFormData.created_at = new Date(
        Date.now() + 3 * 60 * 60 * 1000
      )
        .toISOString()
        .split("Z")[0];
      this.AuditObservationFormData.updated_at =
        this.AuditObservationFormData.created_at;
      const endpoint = "audit_observations";
      const json_data = JSON.stringify(this.AuditObservationFormData);
      const response = await postData(this.$router, 
        endpoint,
        json_data,
        "Creating Observation..."
      );
      if (response.ok) {
        Swal.fire({
          text: "Observation Created Successfully",
          icon: "success",
          buttonsStyling: false,
          confirmButtonText: "Ok, got it!",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        }).then((result) => {
          if (result.isConfirmed) {
            this.$router.push({ name: "AuditObservations" });
          }
        });
      }
    },
    async getData(id) {
      const url = `audit_observations/${id}`;
      this.AuditObservationFormData = await getData(this.$router, url);
    },
    async updateData(id) {
      const endpoint = `audit_observations`;
      this.AuditObservationFormData.updated_at = new Date(
        Date.now() + 3 * 60 * 60 * 1000
      )
        .toISOString()
        .split("Z")[0];
      const json_data = JSON.stringify(this.AuditObservationFormData);
      const response = await updateData(this.$router, endpoint, json_data, id, "Updating Observation...");
      if (response.ok) {
        Swal.fire({
          text: "Observation Updated Successfully",
          icon: "success",
          buttonsStyling: false,
          confirmButtonText: "Ok, got it!",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        }).then((result) => {
          if (result.isConfirmed) {
            this.$router.push({ name: "AuditObservations" });
          }
        });
      }
    },
    async fetchReferenceOptions() {
      const endpoint = "audit_references";
      const data = await getData(this.$router, endpoint);
      this.ReferenceOptions = data.map((reference) => ({
        key: reference.id,
        value: reference.title,
      }));
      this.ReferenceOptionsArabic = data.map((reference) => ({
        key: reference.id,
        value: reference.title_ar,
      }));
    },
    addToReferences() {
      if (this.selectedReference) {
        if (this.AuditObservationFormData.reference) {
          this.AuditObservationFormData.reference += ", ";
        }
        this.AuditObservationFormData.reference += this.selectedReference;
      }
    },
    deleteLastItem() {
      if (this.AuditObservationFormData.reference) {
        const referencesArray =
          this.AuditObservationFormData.reference.split(", ");
        referencesArray.pop();
        this.AuditObservationFormData.reference = referencesArray.join(", ");
      }
    },
    addToReferencesArabic() {
      if (this.selectedReferenceArabic) {
        if (this.AuditObservationFormData.reference_ar) {
          this.AuditObservationFormData.reference_ar += ", ";
        }
        this.AuditObservationFormData.reference_ar +=
          this.selectedReferenceArabic;
      }
    },
    deleteLastItemArabic() {
      if (this.AuditObservationFormData.reference_ar) {
        const referencesArray =
          this.AuditObservationFormData.reference_ar.split(", ");
        referencesArray.pop();
        this.AuditObservationFormData.reference_ar = referencesArray.join(", ");
      }
    },
    async checkForm() {
      let errors = "";

      if (!this.AuditObservationFormData.title) {
        errors += "Title is required. \n";
      }
      if (!this.AuditObservationFormData.title_ar) {
        errors += "Title (In Arabic) is required. \n";
      }
      if (!this.AuditObservationFormData.description) {
        errors += "Description is required. \n";
      }
      if (!this.AuditObservationFormData.description_ar) {
        errors += "Description (In Arabic) is required. \n";
      }
      if (!this.AuditObservationFormData.impact) {
        errors += "Impact is required. \n";
      }
      if (!this.AuditObservationFormData.impact_ar) {
        errors += "Impact (In Arabic) is required. \n";
      }
      if (!this.AuditObservationFormData.recommendation) {
        errors += "Recommendation is required. \n";
      }
      if (!this.AuditObservationFormData.recommendation_ar) {
        errors += "Recommendation (In Arabic) is required. \n";
      }
      if (!this.AuditObservationFormData.root_cause) {
        errors += "Root Cause is required. \n";
      }
      if (!this.AuditObservationFormData.root_cause_ar) {
        errors += "Root Cause (In Arabic) is required. \n";
      }
      if (!this.AuditObservationFormData.reference) {
        errors += "Reference is required. \n";
      }
      if (!this.AuditObservationFormData.reference_ar) {
        errors += "Reference Arabic is required. \n";
      }

      if (errors === "") {
        if (this.$props.id) {
          await this.updateData(this.$props.id);
        } else {
          await this.createData();
        }
      } else {
        Swal.fire({
          text: errors, 
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Ok, got it!",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
      }
    },
  },
  async mounted() {
    await this.fetchReferenceOptions();
    if (this.$props.id) {
      setCurrentPageBreadcrumbs("Update Audit Observations");
      await this.getData(this.$props.id);
    } else {
      setCurrentPageBreadcrumbs("Create Audit Observations");
    }
    this.loading = false;
  },
});
</script>