<template>
  <div v-if="loading">
    <CircularLoadingBar></CircularLoadingBar>
  </div>
  <div v-else>
    <div class="card">
      <!--begin::Body-->
      <div class="card-body py-0">
        <div class="card-header border-0" style="background-color: transparent">
          <!--begin::Card title-->
          <div class="card-title">
            <!--begin::Search-->
            <div class="d-flex align-items-center position-relative my-1">
              <input
                type="text"
                v-model="search_term"
                @input="search_reports()"
                class="form-control form-control-solid w-250px ps-15"
                placeholder="Search Client"
                style="border: 1px solid grey"
              />
            </div>
            <!--end::Search-->
          </div>
          <!--begin::Card toolbar-->
          <div class="card-toolbar">
            <!--begin::Toolbar-->
            <div class="d-flex justify-content-end">
              <!--begin::Add Client -->
              <button
                type="button"
                class="btn btn-primary"
                @click="new_client_page()"
              >
                New Client
              </button>
              <!--end::Add Client -->
            </div>
            <!--end::Toolbar-->
          </div>
          <!--end::Card toolbar-->
        </div>
        <div class="tab-content">
          <!--begin::Tap pane-->
          <div class="tab-pane fade active show">
            <!--begin::Table container-->
            <div>
              <!--begin::Table-->
              <table
                class="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4"
              >
                <!--begin::Table head-->
                <thead>
                  <!-- <tr class="border-0"></tr> -->
                </thead>
                <!--end::Table head-->
                <!--begin::Table body-->
                <tbody>
                  <tr>
                    <td>
                      <div class="align-top symbol symbol-45px me-2">
                        <span class="symbol-label"> # </span>
                      </div>
                    </td>

                    <td
                      v-for="label in column_labels"
                      :key="label.id"
                      class="align-top text-dark fw-bold text-hover-primary mb-1 fs-6"
                    >
                      {{ label }}
                    </td>
                  </tr>

                  <tr
                    v-for="(client, index) in filtered_clients_data"
                    :key="index"
                  >
                    <td>
                      <div class="symbol symbol-45px me-2">
                        <span class="symbol-label">
                          {{ index + 1 }}
                        </span>
                      </div>
                    </td>

                    <td class="text-start">
                      <TruncatedText
                        :text="client['client_name']"
                        maxLength=40
                      ></TruncatedText>
                    </td>

                    <td class="text-start">
                      <TruncatedText
                        :text="client['client_name_ar']"
                        maxLength=40
                      ></TruncatedText>
                    </td>

                    <td class="text-start">
                      <TruncatedText
                        :text="client['client_shortcode']"
                        maxLength=40
                      ></TruncatedText>
                    </td>

                    <td class="text-start">
                      <TruncatedText
                        :text="client['client_description']"
                        maxLength=40
                      ></TruncatedText>
                    </td>

                    <td class="text-start">
                      <span
                        v-if="client['client_status'] != '1'"
                        class="badge badge-light-danger"
                        >Disabled</span
                      >
                      <span v-else class="badge badge-light-success"
                        >Active</span
                      >
                      <text
                        @click="change_status(client['client_id'])"
                        class="text-muted fw-semobold d-block"
                        style="cursor: pointer"
                        >Click to change</text
                      >
                    </td>

                    <td class="text-start">
                      <div class="d-flex align-items-center">
                        <i
                          class="bi bi-pencil-square mx-2 custom-icon"
                          style="cursor: pointer"
                          title="Edit client"
                          @click="client_edit_page(client['client_id'])"
                        ></i>
                        <i
                          class="bi bi-trash mx-2 custom-icon"
                          style="cursor: pointer"
                          title="Delete Client"
                          @click="delete_client(client['client_id'])"
                        ></i>
                      </div>
                    </td>
                  </tr>
                </tbody>
                <!--end::Table body-->
              </table>
            </div>
            <!--end::Table-->
          </div>
          <!--end::Tap pane-->
        </div>
      </div>
      <!--end::Body-->
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import {
  getData,
  deleteData,
  postData,
} from "@/core/services/ApiService";
import CircularLoadingBar from "@/components/widgets/circularloadingbar/CircularLoadingBar.vue";
import TruncatedText from "@/components/TruncatedText.vue";

export default defineComponent({
  name: "ClientsList",
  components: {
    CircularLoadingBar,
    TruncatedText,
  },
  data() {
    return {
      loading: true,
      client_list: [],
      search_term: "",
      column_labels: [
        "Name",
        "Name Arabic",
        "Short Code",
        "Description",
        "Status",
        "Actions",
      ],
      filtered_clients_data: [],
    };
  },
  setup() {
    setCurrentPageBreadcrumbs("Client List");
  },

  methods: {
    async getData() {
      this.client_list = await getData(this.$router, "clients");
      this.filtered_clients_data = this.client_list;
    },

    async delete_client(id) {
      const idToDelete = id;
      await deleteData(
        this.$router,
        "clients",
        idToDelete,
        "Deleting client..."
      );

      for (let i = 0; i < this.client_list.length; i++) {
        if (this.client_list[i]["client_id"] === idToDelete) {
          this.client_list.splice(i, 1);
        }
      }

      this.filtered_clients_data = this.client_list;
    },

    search_reports() {
      const inputValue = this.search_term.toLowerCase();
      this.filtered_clients_data = this.client_list.filter((option) =>
        String(option["client_name"]).toLowerCase().includes(inputValue)
      );
    },

    client_edit_page(id) {
      this.$router.push({
        name: "ClientListForm",
        params: { id: id },
      });
    },

    async change_status(id) {
      const url = `clients/changestatus/${id}`;
      let response = await postData(this.$router, url, null,"Changing Status...");
      if (response.ok) {
        for (let i = 0; i < this.client_list.length; i++) {
          if (this.client_list[i]["client_id"] === id) {
            this.client_list[i]["client_status"] =
              this.client_list[i]["client_status"] === 1 ? 0 : 1;
          }
        }
        this.filtered_clients_data = this.client_list;
      }
    },

    new_client_page() {
      this.$router.push({ name: "ClientListForm" });
    },
  },

  async mounted() {
    await this.getData();
    this.loading = false;
  },
});
</script>
