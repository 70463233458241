<template>
  <div v-if="loading">
    <CircularLoadingBar></CircularLoadingBar>
  </div>
  <div v-else>
    <div class="card">
      <!--begin::Body-->
      <div class="card-body py-0">
        <div class="card-header border-0" style="background-color: transparent">
          <!--begin::Card title-->
          <div class="card-title">
            <!--begin::Search-->
            <div class="d-flex align-items-center position-relative my-1">
              <input
                type="text"
                v-model="search_term"
                @input="search_reports()"
                class="form-control form-control-solid w-250px ps-15"
                placeholder="Search Department"
                style="border: 1px solid grey"
              />
            </div>
            <!--end::Search-->
          </div>
          <!--begin::Card toolbar-->
          <div class="card-toolbar">
            <!--begin::Toolbar-->
            <div class="d-flex justify-content-end">
              <!--begin::Add Department -->
              <button
                type="button"
                class="btn btn-primary"
                @click="new_department_page()"
              >
                New Department
              </button>
              <!--end::Add Department -->
            </div>
            <!--end::Toolbar-->
          </div>
          <!--end::Card toolbar-->
        </div>
        <div class="tab-content">
          <!--begin::Tap pane-->
          <div class="tab-pane fade active show">
            <!--begin::Table container-->
            <div>
              <!--begin::Table-->
              <table
                class="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4"
              >
                <!--begin::Table head-->
                <thead>
                  <tr class="border-0"></tr>
                </thead>
                <!--end::Table head-->
                <!--begin::Table body-->
                <tbody>
                  <tr>
                    <td>
                      <div class="align-top symbol symbol-45px me-2">
                        <span class="symbol-label"> # </span>
                      </div>
                    </td>

                    <td
                      v-for="label in column_labels"
                      class="align-top text-dark fw-bold text-hover-primary mb-1 fs-6"
                    >
                      {{ label }}
                    </td>
                  </tr>

                  <tr
                    v-for="(department, index) in filtered_departments_data"
                    :key="index"
                  >
                    <td>
                      <div class="symbol symbol-45px me-2">
                        <span class="symbol-label">
                          {{ index + 1 }}
                        </span>
                      </div>
                    </td>

                    <td class="text-start">
                      <TruncatedText
                        :text="department['name']"
                        maxLength=25
                      ></TruncatedText>
                    </td>

                    <td class="text-start">
                      <TruncatedText
                        :text="department['name_ar']"
                        maxLength=25
                      ></TruncatedText>
                    </td>

                    <td class="text-start">
                      <TruncatedText
                        :text="department['description']"
                        maxLength=35
                      ></TruncatedText>
                    </td>

                    <td class="text-start">
                      <TruncatedText
                        :text="department['description_ar']"
                        maxLength=35
                      ></TruncatedText>
                    </td>

                    <td class="text-start">
                      <TruncatedText
                        :text="department['parent']"
                        maxLength=30
                      ></TruncatedText>
                    </td>

                    <td class="text-start">
                      <div class="d-flex align-items-center">
                        <i
                          class="bi bi-pencil-square mx-2 custom-icon"
                          style="cursor: pointer"
                          title="Edit Department"
                          @click="department_edit_page(department['id'])"
                        ></i>
                        <i
                          class="bi bi-trash mx-2 custom-icon"
                          style="cursor: pointer"
                          title="Delete Department"
                          @click="delete_department(department['id'])"
                        ></i>
                      </div>
                    </td>
                  </tr>
                </tbody>
                <!--end::Table body-->
              </table>
            </div>
            <!--end::Table-->
          </div>
          <!--end::Tap pane-->
        </div>
      </div>
      <!--end::Body-->
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { getData, deleteData } from "@/core/services/ApiService";
import CircularLoadingBar from "@/components/widgets/circularloadingbar/CircularLoadingBar.vue";
import TruncatedText from "@/components/TruncatedText.vue";

export default defineComponent({
  name: "AuditDepartments",
  components: {
    CircularLoadingBar,
    TruncatedText,
  },
  data() {
    return {
      loading: true,
      departmentsData: [],
      search_term: "",
      column_labels: [
        "Name",
        "Name (In Arabic)",
        "Description",
        "Description (In Arabic)",
        "Parent",
        "Action",
      ],
      filtered_departments_data: [],
    };
  },
  setup() {
    setCurrentPageBreadcrumbs("Departments");
  },

  methods: {
    async getData() {
      this.departmentsData = await getData(this.$router, "departments");
      this.filtered_departments_data = this.departmentsData;
    },
    async delete_department(id) {
      const idToDelete = id;
      await deleteData(this.$router, "departments", idToDelete, "Deleting Department...");

      for (let i = 0; i < this.departmentsData.length; i++) {
        if (this.departmentsData[i]["id"] === idToDelete) {
          this.departmentsData.splice(i, 1);
        }
      }
      this.filtered_departments_data = this.departmentsData;
    },
    search_reports() {
      const inputValue = this.search_term.toLowerCase();
      this.filtered_departments_data = this.departmentsData.filter((option) =>
        String(option["name"]).toLowerCase().includes(inputValue)
      );
    },
    department_edit_page(id) {
      this.$router.push({
        name: "AuditDepartmentForm",
        params: { id: id },
      });
    },
    new_department_page() {
      this.$router.push({ name: "AuditDepartmentForm" });
    },
  },
  async mounted() {
    await this.getData();
    this.loading = false;
  },
});
</script>
