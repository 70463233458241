import { createApp } from "vue";
import App from "./App.vue";

import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
import i18n from "@/core/plugins/i18n";

import { initApexCharts } from "@/core/plugins/apexcharts";
import { initInlineSvg } from "@/core/plugins/inline-svg";
import { initVeeValidate } from "@/core/plugins/vee-validate";

import "@/core/plugins/prismjs";
const app = createApp(App);

// Check authentication before creating the app
async function checkAuth() {
  const authDataString = localStorage.getItem("auth");
  const authData = authDataString ? JSON.parse(authDataString) : null;
  const isAuthenticated = authData && authData.isAuthenticated;
  if (isAuthenticated) {
    const gClient = await store.dispatch("initializeAuth");
    if (!gClient) {
      router.push({ name: "sign-in" });
    }
  }
  else{
    router.push({ name: "sign-in" });
  }
 
}
checkAuth();
app.use(store);
app.use(router);
app.use(ElementPlus);

initApexCharts(app);
initInlineSvg(app);
initVeeValidate();

app.use(i18n);

app.mount("#app");

