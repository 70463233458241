<template>
  <div v-if="loading">
    <CircularLoadingBar></CircularLoadingBar>
  </div>
  <div v-else>
    <div class="card">
      <!--begin::Body-->
      <div class="card-body py-0">
        <div class="card-header border-0" style="background-color: transparent">
          <!--begin::Card title-->
          <div class="card-title">
            <!--begin::Search-->
            <div class="d-flex align-items-center position-relative my-1">
              <input
                type="text"
                v-model="search_term"
                @input="search_reports()"
                class="form-control form-control-solid w-250px ps-15"
                placeholder="Search Sector"
                style="border: 1px solid grey"
              />
            </div>
            <!--end::Search-->
          </div>
          <!--begin::Card toolbar-->
          <div class="card-toolbar">
            <!--begin::Toolbar-->
            <div class="d-flex justify-content-end">
              <!--begin::Add Sector -->
              <button
                type="button"
                class="btn btn-primary"
                @click="new_sector_page()"
              >
                New Sector
              </button>
              <!--end::Add Sector -->
            </div>
            <!--end::Toolbar-->
          </div>
          <!--end::Card toolbar-->
        </div>
        <div class="tab-content">
          <!--begin::Tap pane-->
          <div class="tab-pane fade active show">
            <!--begin::Table container-->
            <div>
              <!--begin::Table-->
              <table
                class="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4"
              >
                <!--begin::Table head-->
                <thead>
                  <tr class="border-0"></tr>
                </thead>
                <!--end::Table head-->
                <!--begin::Table body-->
                <tbody>
                  <tr>
                    <td>
                      <div class="align-top symbol symbol-45px me-2">
                        <span class="symbol-label"> # </span>
                      </div>
                    </td>

                    <td
                      v-for="label in column_labels"
                      :key="label.id"
                      class="align-top text-dark fw-bold text-hover-primary mb-1 fs-6"
                    >
                      {{ label }}
                    </td>
                  </tr>

                  <tr
                    v-for="(sector, index) in filtered_sectors_data"
                    :key="index"
                  >
                    <td>
                      <div class="symbol symbol-45px me-2">
                        <span class="symbol-label">
                          {{ index + 1 }}
                        </span>
                      </div>
                    </td>

                    <td class="text-start">
                      <span>
                        {{
                          sector["name"]
                            ? sector["name"].length > 0
                              ? sector["name"].length > 50
                                ? sector["name"].substring(0, 50) + "..."
                                : sector["name"]
                              : "empty"
                            : "empty"
                        }}
                      </span>
                    </td>
<!-- 
                    <td class="text-start">
                      <span
                        v-if="sector['status'] != 'Open'"
                        class="badge badge-light-danger"
                        >{{ sector["status"] }}</span
                      >
                      <span v-else class="badge badge-light-success">{{
                        sector["status"]
                      }}</span>
                      <text
                        @click="switch_status(sector['id'])"
                        class="text-muted fw-semobold d-block"
                        style="cursor: pointer"
                        >Click to change</text
                      >
                    </td> -->


                    <td class="text-start">
                      <span v-if="sector['status'] == '0'" class="badge badge-light-danger">Disabled</span>
                      <span v-else class="badge badge-light-success">Active</span>
                      <text @click="change_status(sector.id)" class="text-muted fw-semobold d-block"
                        style="cursor: pointer">Click to
                        change</text>
                    </td>


                    <td class="text-start">
                      <div class="d-flex align-items-center">
                        <i
                          class="bi bi-pencil-square mx-2 custom-icon"
                          style="cursor: pointer"
                          title="Edit Sector"
                          @click="sector_edit_page(sector['id'])"
                        ></i>
                        <i
                          class="bi bi-trash mx-2 custom-icon"
                          style="cursor: pointer"
                          title="Delete Sector"
                          @click="delete_sector(sector['id'])"
                        ></i>
                      </div>
                    </td>
                  </tr>
                </tbody>
                <!--end::Table body-->
              </table>
            </div>
            <!--end::Table-->
          </div>
          <!--end::Tap pane-->
        </div>
      </div>
      <!--end::Body-->
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { getData, deleteData } from "@/core/services/ApiService";
import CircularLoadingBar from "@/components/widgets/circularloadingbar/CircularLoadingBar.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";

export default defineComponent({
  name: "SectorList",
  components: {
    CircularLoadingBar,
  },
  data() {
    return {
      loading: true,
      SectorListData: [],
      search_term: "",
      column_labels: ["Name", "Status", "Action"],
      filtered_sectors_data: [],
    };
  },
  setup() {
    setCurrentPageBreadcrumbs("Sector List");
  },

  methods: {
    async getData() {
      this.SectorListData = await getData(this.$router, "sectors");
      this.filtered_sectors_data = this.SectorListData;
    },

    async delete_sector(idToDelete) {
      const result = await Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'Cancel',
      });

      if (result.isConfirmed) {
        try {
        await deleteData(this.$router, "sectors", idToDelete, "Deleting Sector...");
        for (let i = 0; i < this.SectorListData.length; i++) {
          if (this.SectorListData[i]["id"] === idToDelete) {
            this.SectorListData.splice(i, 1);
          }
        }
        this.filtered_sectors_data = this.SectorListData;
        Swal.fire(
            'Deleted!',
            'Sector has been deleted.',
            'success'
          );

      } catch (error) {
          Swal.fire(
            'Error!',
            'There was an issue deleting the Sector.',
            'error'
          );
        }
      }
    }, 
    search_reports() {
      const inputValue = this.search_term.toLowerCase();
      this.filtered_sectors_data = this.SectorListData.filter((option) =>
        String(option["name"]).toLowerCase().includes(inputValue)
      );
    },
    sector_edit_page(id) {
      this.$router.push({
        name: "SectorListForm",
        params: { id: id },
      });
    },
    new_sector_page() {
      this.$router.push({ name: "SectorListForm" });
    },

    async change_status(id) {
      console.log('I am here change function');

      const url = `sectors/changestatus/${id}`;
      await getData(this.$router, url);

      console.log("Before");
      console.log(this.filtered_sectors_data);

      for (let i = 0; i < this.SectorListData.length; i++) {
        if (this.SectorListData[i]["id"] === id) {
          console.log("Sector ID: ", id);
          // Correcting the assignment of sector
          this.SectorListData[i]["status"] = this.SectorListData[i]["status"] === 1 ? 0 : 1;
        }
      }

      this.filtered_sectors_data = this.SectorListData;
      console.log("After");
      console.log(this.filtered_sectors_data);
    },



   
  },

  async mounted() {
    await this.getData();
    this.loading = false;
  },
});
</script>
